import React from "react";
import Lottie from "react-lottie-player";
import { colors, Container } from "../layout";
import type { DatoCmsHero } from "../page";

const Element: React.FC<Omit<DatoCmsHero, "__typename">> = props => (
  <>
    <div
      css={`
        background: ${colors.gray};
        padding-top: 121px;
        padding-bottom: 92px;
        @media (max-width: 640px) {
          padding-top: 62px;
          padding-bottom: 71px;
        }
      `}
    >
      <Container>
        <div
          css={`
            display: grid;
            grid-gap: 100px;
            @media (max-width: 640px) {
              grid-gap: 43px;
            }
          `}
        >
          <h1
            css={`
              ${colors.disp}
              font-size: 114px;
              line-height: 92px;
              text-align: center;
              color: #000;
              @media (max-width: 640px) {
                font-weight: 400;
                font-size: 80px;
                line-height: 1;
              }
            `}
          >
            {props.titleH1}
          </h1>
          <p
            css={`
              ${colors.ag.H3}
              white-space: pre-wrap;
              text-align: center;
            `}
          >
            {props.subtitle}
          </p>
        </div>
        <div style={{ height: 78 }} />
        <div
          css={`
            display: flex;
            width: 100%;
            justify-content: center;
            flex-basis: max-content;
            gap: 42px;
            flex-wrap: wrap;
            > div {
              max-width: 288px;
              @media (max-width: 640px) {
                max-width: 130px;
              }
            }
          `}
        >
          {props.jsonAnimatedImages.map(({ json, description }, k) => (
            <>
              <div css={``}>
                <div
                  css={`
                    border: 1px solid #000000;
                    border-radius: 17.8601px;
                  `}
                >
                  <Lottie loop animationData={JSON.parse(json)} play />
                </div>
                <div style={{ height: 19 }} />
                <div
                  css={`
                    display: flex;
                    gap: 20px;
                    @media (max-width: 640px) {
                      gap: 8px;
                    }
                  `}
                >
                  <div
                    css={`
                      ${colors.sans};
                      font-size: 15px;
                      letter-spacing: -0.015em;
                    `}
                  >
                    {("0" + (k + 1)).substring(-2)}
                  </div>
                  <div
                    css={`
                      ${colors.text};
                      font-size: 21px;
                      line-height: 26px;
                      letter-spacing: -0.015em;
                    `}
                  >
                    {description}
                  </div>
                </div>
              </div>
            </>
          ))}
        </div>
      </Container>
    </div>
  </>
);

export default Element;
