import React from "react";
import { colors, Container } from "../layout";
import type { DatoCmsServicesToDo } from "../page";
import { MyLink } from "./DatoCmsServicesContent";

const Element: React.FC<Omit<DatoCmsServicesToDo, "__typename">> = props => (
  <>
    <div
      css={`
        background: ${colors.gray};
      `}
    >
      <Container>
        <div
          css={`
            height: 112px;
            @media (max-width: 640px) {
              height: 39px;
            }
          `}
        />
        <h1
          css={`
            ${colors.ag.H1}
            text-align: center;
          `}
        >
          {props.title}
        </h1>
        <div
          css={`
            height: 100px;
            @media (max-width: 640px) {
              height: 69px;
            }
          `}
        />
        <div
          css={`
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 75px;
            @media (max-width: 640px) {
              grid-template-columns: 1fr;
              grid-gap: 50px;
            }
          `}
        >
          {props.toDoList.map((todo, k) => (
            <>
              <div
                css={`
                  display: flex;
                  gap: 35px;
                  flex-direction: column;
                `}
              >
                <div
                  css={`
                    text-align: center;
                    > img {
                      width: 100%;
                      max-width: ${todo.image.width}px;
                      @media (max-width: 640px) {
                        max-width: 200px;
                      }
                    }
                  `}
                >
                  <img
                    src={todo.image.url}
                    width={todo.image.width}
                    height={todo.image.height}
                    alt={todo.image.alt}
                  />
                </div>
                <div>
                  <div
                    css={`
                      display: flex;
                      gap: 20px;
                      align-items: center;
                    `}
                  >
                    <div
                      css={`
                        ${colors.sans};
                        font-size: 15px;
                        letter-spacing: -0.015em;
                        position: relative;
                        top: -1px;
                      `}
                    >
                      {("0" + (k + 1)).substring(-2)}
                    </div>
                    <div
                      css={`
                        ${colors.sans};
                        font-weight: 600;
                        font-size: 15px;
                        line-height: 19px;
                        letter-spacing: 0.08em;
                        text-transform: uppercase;
                      `}
                    >
                      {todo.title}
                    </div>
                  </div>
                  <div style={{ height: 10 }} />
                  <div
                    dangerouslySetInnerHTML={{ __html: todo.bulletPoints }}
                    css={`
                      ${colors.text}
                      font-size: 21px;
                      line-height: 30px;
                      letter-spacing: -0.015em;
                      ul {
                        list-style: disc;
                        padding-left: 20px;
                      }
                    `}
                  />
                </div>
              </div>
            </>
          ))}
        </div>
        <div
          css={`
            height: 114px;
            @media (max-width: 640px) {
              height: 59px;
            }
          `}
        />
        <h2
          css={`
            ${colors.ag.H3}
            text-align: center;
          `}
        >
          {props.description}
        </h2>
        <div style={{ height: 44 }} />
        <div
          css={`
            text-align: center;
          `}
        >
          <MyLink
            cta={props.cta}
            css={`
              ${colors.button}
              ${colors.ag["Body Text CTA"]}
              display: inline-block;
              margin: 0 auto 0;
            `}
          />
        </div>
        <div
          css={`
            height: 94px;
            @media (max-width: 640px) {
              height: 60px;
            }
          `}
        />
      </Container>
    </div>
  </>
);

export default Element;
