import React from "react";
import { colors, Container } from "../layout";
import { DatoCmsHalfHalfImageLeft } from "../page";
import "styled-components/macro";
import { MyLink } from "./DatoCmsServicesContent";

const Element: React.FC<
  Omit<DatoCmsHalfHalfImageLeft, "__typename">
> = props => (
  <>
    <div
      css={`
        background: #ffce2e;
        display: grid;
        grid-template-columns: 1fr 1fr;
      `}
    >
      <div
        css={`
          grid-area: 1 / 1 / span 1 / span 1;
          background: url(${props.image.url});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center center;
          @media (max-width: 1024px) {
            aspect-ratio: ${props.image.width} / ${props.image.height};
            grid-area: 1 / 1 / span 1 / span 2;
          }
        `}
      />
      <div
        css={`
          grid-area: 1 / 1 / span 1 / span 2;
          border: 1px solid #000000;
          border-radius: 17.4565px;
          margin: 33px;
          @media (max-width: 1024px) {
            grid-area: 1 / 1 / span 1 / span 2;
            margin: 14px;
          }
        `}
      >
        <Container
          css={`
            display: grid;
            grid-template-columns: 1fr 1fr;
          `}
        >
          <div
            css={`
              grid-area: 1 / 2 / span 1 / span 1;
              padding: 70px;
              @media (max-width: 1024px) {
                grid-area: 1 / 1 / span 1 / span 2;
                padding: 12px;
              }
            `}
          >
            <div
              css={`
                @media (max-width: 1024px) {
                  aspect-ratio: ${props.image.width} / ${props.image.height};
                  margin-bottom: 25px;
                  margin-left: -50px;
                  margin-right: -50px;
                  margin-top: -26px;
                }
              `}
            />
            <h2
              css={`
                ${colors.ag.H2}
              `}
            >
              {props.title}
            </h2>
            <div
              css={`
                height: 33px;
                @media (max-width: 1024px) {
                  height: 25px;
                }
              `}
            />
            <div
              dangerouslySetInnerHTML={{ __html: props.bulletPoints }}
              css={`
                ${colors.ag["Body P Large"]}
                ul {
                  list-style: disc;
                  padding-left: 20px;
                }
              `}
            />
            <div
              css={`
                height: 72px;
                @media (max-width: 1024px) {
                  height: 54px;
                }
              `}
            />
            <MyLink
              cta={props.cta}
              css={`
                ${colors.button}
                ${colors.ag["Body Text CTA"]}
                display: inline-block;
                margin: 0 auto 0;
                background: #fff;
                &:hover {
                  background: ${colors.primary};
                }
              `}
            />
            <div
              css={`
                @media (max-width: 1024px) {
                  height: 25px;
                }
              `}
            />
          </div>
        </Container>
      </div>
    </div>
  </>
);

export default Element;
