import React from "react";
import { colors, Container } from "../layout";
import { DatoCmsReferenceHighlight, NodesContext } from "../page";
import { MyLink } from "./DatoCmsServicesContent";
import { css } from "styled-components/macro";

const Reference: React.FC<{
  reference: DatoCmsReferenceHighlight["referencePosts"][0];
  k: number;
  cta: DatoCmsReferenceHighlight["cta"];
}> = ({ reference, k, cta }) => {
  const { locale } = React.useContext(NodesContext);
  return (
    <div
      css={`
        padding: 85px 0;
        @media (max-width: 640px) {
          padding: 50px 0;
          padding-bottom: 40px;
        }
      `}
    >
      <p
        css={`
          ${colors.ag.H5}
        `}
      >
        {reference.client}
      </p>
      <div style={{ height: 20 }} />
      <h1
        css={`
          ${colors.ag.H1}
          white-space: pre-wrap;
        `}
      >
        {"\u{201d}"}
        {reference.quote}
        {"\u{201d}"}
      </h1>
      <div style={{ height: 35 }} />
      <div
        css={`
          > p {
            ${colors.ag["Body P Large"]}
          }
        `}
        dangerouslySetInnerHTML={{
          __html: reference.nameTitle,
        }}
      />
      <div style={{ height: 65 }} />
      <div
        css={`
          display: flex;
          flex-wrap: wrap;
          flex-basis: min-content;
          gap: 26px;
        `}
      >
        <MyLink
          cta={{
            linkText: (() => {
              function assertNever(shouldBeNever: never): never {
                throw new Error("Was not never: " + shouldBeNever);
              }
              switch (locale) {
                case "fi":
                  return "Lue lisää";
                case "en":
                  return "Read more";
                default:
                  assertNever(locale);
              }
            })(),
            linkTo: {
              originalId: reference.originalId,
              locale: reference.locale,
            },
          }}
          css={`
            ${colors.button}
            ${colors.ag["Body Text CTA"]}
            ${k === 0 &&
            css`
              background: #fff;
              &:hover {
                background: ${colors.primary};
              }
            `}
          `}
        />
        {cta !== null && (
          <MyLink
            cta={cta}
            css={`
              ${colors.button}
              ${colors.ag["Body Text CTA"]}
              ${k === 0 &&
              css`
                background: #fff;
                &:hover {
                  background: ${colors.primary};
                }
              `}
            `}
          />
        )}
      </div>
    </div>
  );
};

const Element: React.FC<
  Omit<DatoCmsReferenceHighlight, "__typename">
> = props => (
  <>
    {props.referencePosts.map((reference, k) => (
      <>
        <div
          css={`
            background: ${[colors.primary, "#FFFFFF", "#C7BFB7"][k % 3]};
            ${props.referencePosts.length === 3 &&
            k > 0 &&
            css`
              display: none;
              @media (max-width: 1024px) {
                display: block;
              }
            `}
          `}
        >
          <Container>
            <Reference reference={reference} k={k} cta={props.cta} />
          </Container>
        </div>
      </>
    ))}
    {props.referencePosts.length === 3 && (
      <div
        css={`
          background: linear-gradient(90deg, #ffffff 50%, #c7bfb7 50%);
          @media (max-width: 1024px) {
            display: none;
          }
        `}
      >
        <Container
          css={`
            display: grid;
            grid-template-columns: 1fr 1fr;
            > div:nth-child(1) {
              padding-right: 85px;
            }
            > div:nth-child(2) {
              padding-left: 85px;
            }
            word-break: break-word;
          `}
        >
          <Reference
            reference={props.referencePosts[1]}
            k={1}
            cta={props.cta}
          />
          <Reference
            reference={props.referencePosts[2]}
            k={2}
            cta={props.cta}
          />
        </Container>
      </div>
    )}
  </>
);

export default Element;
