import React from "react";
import { colors, Container } from "../layout";
import { DatoCmsServicesHero, NodesContext } from "../page";

type Direction = "tr" | "tl" | "br" | "bl";

export const CanvasDemo: React.FC<{
  highlightDirection?: Direction;
  balls: {
    size: number;
    color: string;
    direction: Direction;
  }[];
  blurb: string;
  image: {
    url: string;
    width: number;
    height: number;
  } | null;
}> = ({ highlightDirection, balls, blurb, image }) => {
  const rootRef = React.useRef<HTMLDivElement>(null!);
  const divRef = React.useRef<HTMLDivElement>(null!);
  const cornerRef = React.useRef<HTMLElement>(null!);
  const contentRef = React.useRef<HTMLDivElement>(null!);
  const [size, setSize] = React.useState<{ width: number; height: number }>(
    undefined!
  );
  const [highlightPosition, setHighlightPosition] = React.useState<
    [number, number]
  >(undefined!);
  React.useLayoutEffect(() => {
    var ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        setSize({ width: cr.width, height: cr.height });
        const root = rootRef.current!.getBoundingClientRect();
        if (cornerRef.current === null) continue;
        const rect = cornerRef.current!.getBoundingClientRect();
        const [x, y] = [rect.x - root.x, rect.y - root.y];
        setHighlightPosition([x, y]);
      }
    });
    ro.observe(divRef.current!);
    return () => {
      ro.disconnect();
    };
  }, []);
  const [y, setY] = React.useState(0);
  React.useLayoutEffect(() => {
    const scroll = () => {
      const rect = rootRef.current!.getBoundingClientRect();
      let y = 1 - (rect.top + rect.height) / (window.innerHeight + rect.height);
      if (y < 0) y = 0;
      if (y > 1) y = 1;
      setY(y);
    };
    window.addEventListener("scroll", scroll, false);
    requestAnimationFrame(() => {
      scroll();
    });
    return () => {
      window.removeEventListener("scroll", scroll, false);
    };
  }, [size]);
  const rx = 17.4561;
  const ballPercentages = React.useMemo(() => {
    return balls.map(v => [Math.random(), Math.random()]);
  }, [balls]);
  const ballPositions = React.useMemo(() => {
    if (typeof size === "undefined") return;
    return balls.map((b, i) => {
      const corner = [
        Math.round(rx * 2 + ballPercentages[i][0] * (size.width - rx * 2 * 2)),
        Math.round(rx * 2 + ballPercentages[i][1] * (size.height - rx * 2 * 2)),
      ];
      return {
        corner,
        scrollPosition: Math.random(),
        scrollDirection: b.direction.substring(i % 2, (i % 2) + 1),
      };
    });
  }, [balls, size, rx, ballPercentages]);
  return (
    <>
      <div
        css={`
          display: grid;
          height: 100%;
        `}
        ref={rootRef}
      >
        <div
          css={`
            position: relative;
            grid-area: 1 / 1 / span 1 / span 1;
          `}
          ref={divRef}
        >
          {typeof size !== "undefined" && (
            <svg
              width={size.width}
              height={size.height}
              css={`
                position: absolute;
                top: 0;
                left: 0;
              `}
            >
              <g
                stroke="#000"
                strokeWidth={1 / window.devicePixelRatio}
                fill="none"
              >
                {balls.map((b, i) => {
                  const { corner, scrollDirection } = ballPositions![i];
                  const y0 = (y + 0.5) / 2;
                  return (
                    <>
                      <rect
                        {...(b.direction === "tl"
                          ? {
                              x: corner[0] - size.width,
                              y: corner[1] - size.height,
                            }
                          : {})}
                        {...(b.direction === "tr"
                          ? {
                              x: corner[0],
                              y: corner[1] - size.height,
                            }
                          : {})}
                        {...(b.direction === "br"
                          ? {
                              x: corner[0],
                              y: corner[1],
                            }
                          : {})}
                        {...(b.direction === "bl"
                          ? {
                              x: corner[0] - size.width,
                              y: corner[1],
                            }
                          : {})}
                        width={size.width}
                        height={size.height}
                        rx={rx}
                      />
                    </>
                  );
                })}
              </g>
              {typeof highlightPosition !== "undefined" && (
                <>
                  {highlightDirection === "tr" && (
                    <>
                      <path
                        stroke="#000"
                        strokeWidth={1 / window.devicePixelRatio}
                        d={`M${highlightPosition[0]} ${highlightPosition[1]} L${highlightPosition[0]} 0`}
                      />
                      <path
                        stroke="#000"
                        strokeWidth={1 / window.devicePixelRatio}
                        d={`M${highlightPosition[0]} ${highlightPosition[1]} L${size.width} ${highlightPosition[1]}`}
                      />
                    </>
                  )}
                  {highlightDirection === "tl" && (
                    <>
                      <path
                        stroke="#000"
                        strokeWidth={1 / window.devicePixelRatio}
                        d={`M${highlightPosition[0]} ${highlightPosition[1]} L${highlightPosition[0]} 0`}
                      />
                      <path
                        stroke="#000"
                        strokeWidth={1 / window.devicePixelRatio}
                        d={`M${highlightPosition[0]} ${
                          highlightPosition[1]
                        } L${0} ${highlightPosition[1]}`}
                      />
                    </>
                  )}
                </>
              )}
              {balls.map((b, i) => {
                const { corner, scrollDirection } = ballPositions![i];
                const y0 = (y + 0.5) / 2;
                return (
                  <>
                    {(() => {
                      return (
                        <circle
                          cx={
                            corner[0] +
                            (scrollDirection === "r"
                              ? y0 * (size.width - corner[0])
                              : 0) +
                            (scrollDirection === "l" ? -y0 * corner[0] : 0)
                          }
                          cy={
                            corner[1] +
                            (scrollDirection === "b"
                              ? y0 * (size.height - corner[1])
                              : 0) +
                            (scrollDirection === "t" ? -y0 * corner[1] : 0)
                          }
                          stroke="none"
                          fill={b.color}
                          r={b.size / 2}
                        />
                      );
                    })()}
                  </>
                );
              })}
              {typeof highlightPosition !== "undefined" && (
                <>
                  <circle
                    cx={highlightPosition[0]}
                    cy={highlightPosition[1]}
                    r={40}
                    stroke={"none"}
                    fill={colors.primary}
                  />
                </>
              )}
            </svg>
          )}
        </div>
        {blurb !== "" && (
          <div
            css={`
              grid-area: 1 / 1 / span 1 / span 1;
              position: relative;
              z-index: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 150px;
              padding-bottom: 150px;
            `}
          >
            <div
              css={`
                background: #ffffff;
                border: 1px solid #000000;
                border-radius: 17.4561px;
                padding: 26px 28px;
                ${colors.text}
                font-size: 26px;
                line-height: 30px;
                width: 363px;
                max-width: 100%;
                position: relative;
              `}
              ref={contentRef}
            >
              {highlightDirection === "tr" && (
                <span
                  css={`
                    position: absolute;
                    right: -5px;
                    top: -10px;
                  `}
                  ref={cornerRef}
                />
              )}
              {highlightDirection === "tl" && (
                <span
                  css={`
                    position: absolute;
                    left: -5px;
                    top: -10px;
                  `}
                  ref={cornerRef}
                />
              )}
              {blurb}
            </div>
          </div>
        )}
        {image !== null && (
          <div
            css={`
              grid-area: 1 / 1 / span 1 / span 1;
              position: relative;
              z-index: 1;
              display: flex;
              justify-content: center;
              align-items: center;
              padding-top: 150px;
              padding-bottom: 150px;
              overflow: hidden;
            `}
          >
            <div
              css={`
                background: url(${image.url});
                background-size: cover;
                border-radius: 17.4561px;
                ${colors.text}
                font-size: 26px;
                line-height: 30px;
                /*width: ${image.width}px;*/
                width: 100%;
                position: relative;
                left: 30px;
                aspect-ratio: ${image.width} / ${image.height};
              `}
              ref={contentRef}
            >
              {highlightDirection === "tr" && (
                <span
                  css={`
                    position: absolute;
                    right: -5px;
                    top: -10px;
                  `}
                  ref={cornerRef}
                />
              )}
              {highlightDirection === "tl" && (
                <span
                  css={`
                    position: absolute;
                    left: -5px;
                    top: -10px;
                  `}
                  ref={cornerRef}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const Element: React.FC<Omit<DatoCmsServicesHero, "__typename">> = props => {
  const { locale } = React.useContext(NodesContext);
  return (
    <>
      <div
        css={`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
        `}
      >
        <div
          css={`
            grid-area: 1 / 2 / span 1 / span 1;
            @media (max-width: 1024px) {
              grid-area: 2 / 1 / span 1 / span 2;
              border-top: 1px solid #000;
            }
            @media (max-width: 640px) {
              grid-area: 2 / 1 / span 1 / span 2;
              border-top: 1px solid #000;
            }
          `}
        >
          <CanvasDemo
            blurb={props.blurb}
            image={null}
            highlightDirection="tr"
            balls={[
              {
                size: 43,
                color: "#C87D88",
                direction: "tr",
              },
              {
                size: 26,
                color: "#000",
                direction: "tr",
              },
            ]}
          />
        </div>
        <div
          css={`
            grid-area: 1 / 1 / span 1 / span 2;
          `}
        >
          <Container>
            <div
              css={`
                padding-top: 120px;
                padding-bottom: 72px;
                margin-right: min(50vw, 664px);
                @media (max-width: 1024px) {
                  margin-right: 0;
                  padding-top: 67px;
                }
                @media (max-width: 640px) {
                  margin-right: 0;
                  padding-top: 67px;
                }
              `}
            >
              <h1
                css={`
                  ${colors.ag["H1 iso"]}
                `}
              >
                {props.title}
              </h1>
              <div
                css={`
                  height: 52px;
                  @media (max-width: 640px) {
                    height: 36px;
                  }
                `}
              />
              <p
                css={`
                  ${colors.ag["Body P Large"]}
                `}
              >
                {props.subtitle}
              </p>
            </div>
          </Container>
        </div>
      </div>
      <Container>
        <div
          css={`
            padding-top: 65px;
            padding-bottom: 65px;
            @media (max-width: 640px) {
              padding-top: 30px;
              padding-bottom: 50px;
            }
          `}
        >
          <div
            css={`
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              grid-gap: 40px;
              @media (max-width: 1024px) {
                grid-template-columns: repeat(2, 1fr);
              }
              @media (max-width: 640px) {
                grid-template-columns: 1fr;
              }
            `}
          >
            {props.servicesDescriptions.map((v, k) => (
              <>
                <div
                  css={`
                    @media (max-width: 640px) {
                      text-align: center;
                      > img {
                        max-width: 172px;
                      }
                    }
                  `}
                >
                  <img src={v.image.url} alt={v.image.alt} />
                  <div
                    css={`
                      height: 50px;
                      @media (max-width: 640px) {
                        height: 30px;
                      }
                    `}
                  />
                  <div
                    css={`
                      display: flex;
                      gap: 10px;
                      @media (max-width: 640px) {
                        justify-content: center;
                      }
                    `}
                  >
                    <div
                      css={`
                        ${colors.sans};
                        font-size: 15px;
                        line-height: 18px;
                        letter-spacing: -0.015em;
                        position: relative;
                        top: 4px;
                      `}
                    >
                      {("0" + (k + 1)).substring(-2)}
                    </div>
                    <div
                      css={`
                        ${colors.text};
                        font-size: 21px;
                        line-height: 26px;
                        letter-spacing: -0.015em;
                      `}
                    >
                      {v.title}
                    </div>
                  </div>
                  <div
                    css={`
                      height: 20px;
                    `}
                  />
                  <a
                    href=""
                    css={`
                      ${colors.ag["Body Text CTA"]}
                      text-decoration: none;
                      color: #000;
                    `}
                    onClick={e => {
                      e.preventDefault();
                      document
                        .querySelectorAll(`.DatoCmsServicesContent`)
                        [k].scrollIntoView({ behavior: "smooth" });
                    }}
                  >
                    {(() => {
                      if (locale === "fi") return "Lue lisää";
                      if (locale === "en") return "Read more";
                    })()}
                  </a>
                </div>
              </>
            ))}
          </div>
        </div>
      </Container>
    </>
  );
};

export default Element;
