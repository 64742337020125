import React from "react";
import { colors, Container } from "../layout";
import { DatoCmsAbout } from "../page";
import { MyLink } from "./DatoCmsServicesContent";

const Element: React.FC<Omit<DatoCmsAbout, "__typename">> = props => (
  <>
    <div
      css={`
        padding: 92px 0;
        @media (max-width: 640px) {
          padding: 72px 0;
        }
      `}
    >
      <Container>
        <div
          css={`
            width: 80%;
            margin: 0 auto 0;
          `}
        >
          <div
            css={`
              display: grid;
              grid-template-columns: repeat(4, 1fr);
              grid-gap: 87px 41px;
              @media (max-width: 640px) {
                grid-template-columns: repeat(2, 1fr);
                grid-gap: 46px 39px;
              }
            `}
          >
            {props.logowall[0].customerLogos.map(({ url }) => (
              <div
                key={url}
                css={`
                  background: url(${url});
                  background-repeat: no-repeat;
                  background-size: contain;
                  background-position: center center;
                  aspect-ratio: 251 / 64;
                  @media (max-width: 640px) {
                    aspect-ratio: 136 / 35;
                  }
                `}
              />
            ))}
          </div>
          <div
            css={`
              height: 87px;
              @media (max-width: 640px) {
                height: 74px;
              }
            `}
          />
          <MyLink
            cta={props.cta}
            css={`
              ${colors.button}
              ${colors.ag["Body Text CTA"]}
            display: inline-block;
              margin: 0 auto 0;
            `}
          />
        </div>
      </Container>
    </div>
  </>
);

export default Element;
