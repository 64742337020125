import { graphql, Link } from "gatsby";
import * as React from "react";
import { Helmet } from "react-helmet";
import type {} from "styled-components/cssprop";
import styled, { createGlobalStyle, css } from "styled-components/macro";
import { MyLink } from "./blocks/DatoCmsServicesContent";
import { useStaticQuery } from "gatsby";
import { CTA, NodesContext } from "./page";

const Reset = createGlobalStyle`
html {
  box-sizing: border-box;
}
*, *:before, *:after {
  box-sizing: inherit;
}
body, h1, h2, h3, h4, h5, h6, p, ol, ul {
  margin: 0;
  padding: 0;
  font-weight: normal;
}
ol, ul {
  list-style: none;
}
img, object {
  max-width: 100%;
  height: auto;
}
body {
  overflow-y: scroll;
}
button {
  font-family: inherit;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: freight-text-pro, serif;
  font-weight: 400;
  font-style: normal;
}
body {
  background: #f5f5f5;
}
`;
type Props = {
  pageContext: {
    locale: string;
    originalId: string;
    nodes: {
      originalId: string;
      locale: string;
      path: string;
      title: string;
      showInFooter: boolean;
      showInHeader: boolean;
      treeParent: null | { originalId: string; locale: string };
      isEmpty: boolean;
    }[];
  };
  pathname: string;
  seoMetaTags: { tags: any[] };
};

export const colors = {
  primary: "#FFCE2E",
  gray: "#EDEDED",
  accent: "#C7BFB7",
  text: css`
    font-family: freight-text-pro, serif;
    font-weight: 400;
    font-style: normal;
  `,
  sans: css`
    font-family: freight-sans-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
  `,
  disp: css`
    font-family: freight-display-pro, serif;
    font-weight: 400;
    font-style: normal;
  `,
  ag: {
    Navigaatio: css`
      font-family: freight-sans-pro, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 15px;
      line-height: 30px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #000;
    `,
    H5: css`
      font-family: freight-sans-pro, sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      line-height: 19px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
      color: #000;
      @media (max-width: 640px) {
        font-size: 14px;
        line-height: 16px;
      }
    `,
    H1: css`
      font-family: freight-display-pro, serif;
      font-weight: 400;
      font-style: normal;
      font-size: 46px;
      line-height: 46px;
      color: #000;
      @media (max-width: 640px) {
        font-size: 35px;
        line-height: 37px;
      }
    `,
    "H1 iso": css`
      font-family: freight-display-pro, serif;
      font-weight: 400;
      font-style: normal;
      font-size: 66px;
      line-height: 70px;
      color: #000;
      @media (max-width: 640px) {
        font-size: 35px;
        line-height: 37px;
      }
    `,
    H3: css`
      font-family: freight-text-pro, serif;
      font-weight: 400;
      font-style: normal;
      font-size: 26px;
      line-height: 30px;
      color: #000;
      @media (max-width: 640px) {
        font-size: 22px;
        line-height: 24px;
      }
    `,
    H2: css`
      font-family: freight-display-pro, serif;
      font-weight: 400;
      font-style: normal;
      font-size: 44px;
      line-height: 50px;
      color: #000;
      @media (max-width: 640px) {
        font-size: 30px;
        line-height: 32px;
      }
    `,
    H4: css`
      font-family: freight-text-pro, serif;
      font-weight: 600;
      font-style: normal;
      font-size: 21px;
      line-height: 26px;
      color: #000;
    `,
    "Body P Large": css`
      font-family: freight-text-pro, serif;
      font-weight: 400;
      font-style: normal;
      font-size: 21px;
      line-height: 26px;
      letter-spacing: -0.015em;
      color: #000;
      @media (max-width: 640px) {
        font-size: 20px;
        line-height: 22px;
      }
    `,
    "Body P": css`
      font-family: freight-text-pro, serif;
      font-weight: 400;
      font-style: normal;
      font-size: 18px;
      line-height: 22px;
      letter-spacing: -0.015em;
      color: #000;
    `,
    "Body P Small": css`
      font-family: freight-sans-pro, sans-serif;
      font-weight: 400;
      font-style: normal;
      font-size: 15px;
      line-height: 19px;
      color: #000;
    `,
    "Body Text CTA": css`
      font-family: freight-sans-pro, sans-serif;
      font-weight: 600;
      font-style: normal;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.08em;
      text-transform: uppercase;
    `,
  },
  button: css`
    font-weight: 600;
    letter-spacing: 0.08em;
    text-transform: uppercase;
    border: 1px solid #000000;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 11px 64px;
    cursor: pointer;
    display: block;
    color: #000;
    background: #fff;
    text-decoration: none;
    transition: border-radius 150ms ease-out, background 150ms ease-out;
    background: #ffce2e;
    &:hover {
      border-radius: 10px;
      background: #fff;
    }
  `,
};

export const Container = styled.div<{ width?: number }>`
  width: 100%;
  max-width: ${1280 + 24 + 24}px;
  ${({ width }) =>
    width &&
    css`
      max-width: ${width + 24 + 24}px;
    `}
  margin: 0 auto 0;
  padding-left: 24px;
  padding-right: 24px;
`;

export const Input: React.FC<{
  name: string;
  label: string;
  required?: true;
  type?: string;
}> = ({ name, label, required, type }) => {
  return (
    <>
      <input
        name={name}
        css={`
          background: #ffffff;
          border: none;
          border-radius: 10px 10px 0px 0px;
          border-bottom: 1px solid #000;
          ${colors.text}
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.015em;
          padding: 8px 14px;
          outline: none;
          width: 335px;
          width: 100%;
          max-width: 100%;
          &::placeholder {
            color: rgba(0, 0, 0, 0.6);
          }
          &:focus {
            &::placeholder {
              color: rgba(0, 0, 0, 0);
            }
            border-bottom: 1px solid ${colors.primary};
          }
        `}
        placeholder={label}
        spellCheck="false"
        autoCapitalize="no"
        autoCorrect="no"
        autoComplete="no"
        type={type}
        required={required}
      />
    </>
  );
};

const Sublinks: React.FC<{
  subLinks: { originalId: string; locale: string };
  current: { originalId: string; locale: string };
}> = ({ subLinks, current }) => {
  const { nodes } = React.useContext(NodesContext);
  const ref = React.useRef<HTMLDivElement>(null!);
  React.useLayoutEffect(() => {
    const tick = () => {
      const active = document.querySelector(".active")!;
      const { left } = active.getBoundingClientRect();
      ref.current.style.marginLeft = `${left}px`;
    };
    window.addEventListener("resize", tick, false);
    tick();
    return () => {
      window.removeEventListener("resize", tick, false);
    };
  });
  return (
    <>
      <div
        ref={ref}
        css={`
          ${colors.sans}
          font-size: 15px;
          line-height: 30px;
          letter-spacing: 0.08em;
          text-transform: uppercase;
          a {
            color: inherit;
            text-decoration: none;
            text-transform: none;
            &.active {
              font-weight: 700;
            }
          }
        `}
      >
        {(() => {
          return nodes.map(v => {
            if (v.treeParent === null) return <></>;
            if (
              !(
                v.treeParent.originalId === subLinks.originalId &&
                v.treeParent.locale === subLinks.locale
              )
            )
              return <></>;
            if (!v.showInHeader) return <></>;
            return (
              <>
                <Link
                  to={v.path}
                  className={
                    current.originalId === v.originalId &&
                    current.locale === v.locale
                      ? "active"
                      : undefined
                  }
                >
                  {v.title}
                </Link>
                <br />
              </>
            );
          });
        })()}
      </div>
    </>
  );
};
const classes = (arg0: { [k: string]: boolean }) => {
  const str = Object.keys(arg0)
    .filter(k => arg0[k])
    .join(" ");
  if (str === "") return undefined;
  return str;
};
const Nav: React.FC<{ locale: string; originalId: string }> = ({
  locale,
  originalId,
}) => {
  const { nodes } = React.useContext(NodesContext);
  const parent = nodes.find(
    v => v.originalId === originalId && v.locale === locale
  )!.treeParent;
  const [subLinks, setSubLinks] = React.useState<
    undefined | { originalId: string; locale: string }
  >(undefined);
  const [hide, setHide] = React.useState(false);
  const [mini, setMini] = React.useState(false);
  React.useLayoutEffect(() => {
    let prev = window.scrollY;
    const scroll = () => {
      if (window.scrollY < prev) {
        setHide(false);
      } else if (window.scrollY > 132) {
        setHide(true);
      }
      setMini(window.scrollY > 132 - 87);
      prev = window.scrollY;
    };
    window.addEventListener("scroll", scroll, false);
    return () => {
      window.removeEventListener("scroll", scroll, false);
    };
  }, []);
  return (
    <>
      <div
        css={`
          @media (min-width: 1024px) {
            height: 132px;
          }
        `}
      />
      <div
        css={`
          background: ${colors.primary};
          padding-top: 23px;
          padding-bottom: 23px;
          border-bottom: 1px solid #000000;
          display: none;
          @media (min-width: 1024px) {
            display: block;
          }
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 3;
          transition: padding-top 150ms ease-out, padding-bottom 150ms ease-out,
            transform 150ms ease-out;
          svg {
            transition: max-width 150ms ease-out;
          }
          &.mini {
            padding-top: 1px;
            padding-bottom: 0px;
            svg {
              max-width: 183px;
            }
          }
          &.hide {
            transform: translateY(-100%);
          }
        `}
        className={classes({ mini, hide })}
      >
        <Container>
          <div
            css={`
              display: flex;
              justify-content: center;
              align-items: center;
              > div:nth-child(1) {
                flex: 1;
                line-height: 0;
              }
              gap: 10px 30px;
              flex-wrap: wrap;
              ${colors.sans}
              font-size: 15px;
              line-height: 1.5;
              letter-spacing: 0.08em;
              text-transform: uppercase;

              a,
              span {
                color: inherit;
                text-decoration: none;
                &.active {
                  font-weight: 700;
                }
              }
            `}
          >
            <div>
              <Link
                to={(() => {
                  if (locale === "en") return "/en";
                  return "/";
                })()}
                css={`
                  display: inline-block;
                `}
              >
                <svg
                  width="276"
                  height="85"
                  viewBox="0 0 276 85"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  css={`
                    max-width: 276px;
                    display: inline-block;
                    pointer-events: none;
                  `}
                >
                  <path
                    d="M134.141 35.9584L147.077 21.5542H156.254L137.778 41.6973L155.942 63.5641H146.471L133.848 48.0234C131.717 45.4002 131.291 43.9039 131.291 41.8394C131.281 40.0495 132.02 38.3165 134.141 35.9584Z"
                    fill="black"
                  />
                  <path
                    d="M127.938 21.5542H120.722V63.5641H127.938V21.5542Z"
                    fill="black"
                  />
                  <path
                    d="M167.287 21.5547H160.07V63.5645H167.287V21.5547Z"
                    fill="black"
                  />
                  <path
                    d="M32.7418 42.4744L36.814 47.7114L50.6216 36.9911C53.7751 34.5383 55.6597 32.0287 55.6597 28.8373C55.6597 24.4147 52.4872 21.5547 47.6574 21.5547H28.7643C24.4932 21.5547 21.0366 25.0208 21.0366 29.2824V55.8274C21.0366 60.0984 24.5027 63.5551 28.7643 63.5551H50.0533V56.907H28.2529V28.0797H51.114L32.7418 42.4744Z"
                    fill="black"
                  />
                  <path
                    d="M231.995 42.4744L236.067 47.7114L249.874 36.9911C253.028 34.5478 254.913 32.0287 254.913 28.8373C254.913 24.4147 251.74 21.5547 246.91 21.5547H228.017C223.746 21.5547 220.29 25.0208 220.29 29.2824V55.8274C220.29 60.0984 223.756 63.5551 228.017 63.5551H249.306V56.907H227.496V28.0797H250.357L231.995 42.4744Z"
                    fill="black"
                  />
                  <path
                    d="M97.0363 28.714L87.3104 59.7006L77.4235 28.714C75.8988 23.9316 74.1184 20.6265 68.8719 20.6265C64.9134 20.6265 61.4473 22.691 61.4473 29.197V63.5644H68.6636V24.9544L79.6869 58.2232C81.24 62.8447 83.8633 64.4357 87.3104 64.4357C90.7576 64.4357 93.2388 62.6742 94.6404 58.2232L105.541 24.8881V63.5644H112.757V29.68C112.757 22.691 109.726 20.6265 105.436 20.6265C100.199 20.617 98.4853 24.0926 97.0363 28.714Z"
                    fill="black"
                  />
                  <path
                    d="M205.099 21.4883V60.0225L191.566 29.0834C189.274 23.8274 187.579 20.6265 182.436 20.6265C178.251 20.6265 175.031 22.691 175.031 29.197V63.5644H182.247V25.2858L195.78 56.0072C198.157 61.3957 200.127 64.4262 204.502 64.4262C208.972 64.4262 212.306 62.4564 212.306 55.7988V21.4883H205.099Z"
                    fill="black"
                  />
                </svg>
              </Link>
            </div>
            {(() => {
              return nodes.map(v => {
                if (v.locale !== locale) return <></>;
                if (v.treeParent !== null) return <></>;
                if (!v.showInHeader) return <></>;
                if (v.isEmpty)
                  return (
                    <span
                      css={`
                        cursor: pointer;
                        user-select: none;
                      `}
                      tabIndex={0}
                      className={
                        (subLinks?.originalId === v.originalId &&
                          subLinks?.locale === v.locale) ||
                        (v.originalId === parent?.originalId &&
                          v.locale === parent?.locale)
                          ? "active"
                          : undefined
                      }
                      onClick={e => {
                        e.preventDefault();
                        if (
                          subLinks?.originalId === v.originalId &&
                          subLinks?.locale === v.locale
                        )
                          return setSubLinks(undefined);
                        setSubLinks({
                          originalId: v.originalId,
                          locale: v.locale,
                        });
                      }}
                    >
                      {v.title}
                    </span>
                  );
                return (
                  <Link
                    to={v.path}
                    onClick={e => {
                      setSubLinks(undefined);
                    }}
                    className={
                      typeof subLinks === "undefined" &&
                      locale === v.locale &&
                      originalId === v.originalId
                        ? "active"
                        : undefined
                    }
                  >
                    {v.title}
                  </Link>
                );
              });
            })()}
            <div
              css={`
                display: flex;
                gap: 13px;
                > a {
                  display: inline-block;
                  width: 35px;
                  height: 35px;
                  border: 1px solid #000;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 13px;
                  border-radius: 50%;
                  &.active {
                    background: #fff;
                  }
                  text-transform: uppercase;
                }
              `}
            >
              {(["fi", "en"] as const).map(locale2 => (
                <Link
                  to={(() => {
                    const page = nodes.find(
                      v => v.originalId === originalId && v.locale === locale2
                    );
                    if (typeof page === "undefined") {
                      function assertNever(shouldBeNever: never): never {
                        throw new Error("Was not never: " + shouldBeNever);
                      }
                      switch (locale2) {
                        case "fi":
                          return "/";
                        case "en":
                          return "/en";
                        default:
                          assertNever(locale2);
                      }
                    }
                    return page.path;
                  })()}
                  className={locale === locale2 ? "active" : undefined}
                >
                  <span>{locale2}</span>
                </Link>
              ))}
            </div>
          </div>
        </Container>
        {typeof subLinks !== "undefined" && (
          <Sublinks subLinks={subLinks} current={{ originalId, locale }} />
        )}
      </div>
    </>
  );
};

const MobileNav: React.FC<{ locale: string; originalId: string }> = ({
  originalId,
  locale,
}) => {
  const { nodes } = React.useContext(NodesContext);
  const [open, setOpen] = React.useState(false);
  const [subLinks, setSubLinks] = React.useState<
    undefined | { originalId: string; locale: string }
  >(undefined);
  const parent = nodes.find(
    v => v.originalId === originalId && v.locale === locale
  )!.treeParent;
  const [hide, setHide] = React.useState(false);
  React.useLayoutEffect(() => {
    let prev = window.scrollY;
    const scroll = () => {
      if (window.scrollY < prev) {
        setHide(false);
      } else if (window.scrollY > 71) {
        setHide(true);
      }
      prev = window.scrollY;
    };
    window.addEventListener("scroll", scroll, false);
    return () => {
      window.removeEventListener("scroll", scroll, false);
    };
  }, []);
  return (
    <>
      <div
        css={`
          height: 71px;
          @media (min-width: 1024px) {
            height: 0;
          }
        `}
      />
      <div
        css={`
          display: block;
          @media (min-width: 1024px) {
            display: none;
          }
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          z-index: 3;
          background: ${colors.primary};
          border-bottom: 1px solid #000000;
          transition: transform 150ms ease-out;
          &.hide {
            transform: translateY(-100%);
          }
        `}
        className={classes({ hide })}
      >
        <div
          css={`
            padding: 13px 25px;
          `}
        >
          <div
            css={`
              display: flex;
              justify-content: center;
              align-items: center;
            `}
          >
            <div
              css={`
                font-size: 0;
                flex: 1;
              `}
            >
              <Link
                to={(() => {
                  if (locale === "en") return "/en";
                  return "/";
                })()}
                css={`
                  display: inline-block;
                `}
              >
                <object
                  aria-label="Emkine"
                  type="image/svg+xml"
                  data={require("../images/emkine.svg").default}
                  width="276"
                  height="85"
                  css={`
                    max-width: 145px;
                    display: inline-block;
                    pointer-events: none;
                  `}
                />
              </Link>
            </div>
            <div
              css={`
                display: flex;
                gap: 13px;

                ${colors.sans}
                font-size: 15px;
                line-height: 1.5;
                letter-spacing: 0.08em;
                text-transform: uppercase;

                a {
                  color: inherit;
                  text-decoration: none;
                  &.active {
                    font-weight: 700;
                  }
                }

                > a {
                  display: inline-block;
                  width: 35px;
                  height: 35px;
                  border: 1px solid #000;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  font-size: 13px;
                  border-radius: 50%;
                  &.active {
                    background: #fff;
                  }
                  text-transform: uppercase;
                }
              `}
            >
              {(["fi", "en"] as const).map(locale2 => (
                <Link
                  to={(() => {
                    const page = nodes.find(
                      v => v.originalId === originalId && v.locale === locale2
                    );
                    if (typeof page === "undefined") {
                      function assertNever(shouldBeNever: never): never {
                        throw new Error("Was not never: " + shouldBeNever);
                      }
                      switch (locale2) {
                        case "fi":
                          return "/";
                        case "en":
                          return "/en";
                        default:
                          assertNever(locale2);
                      }
                    }
                    return page.path;
                  })()}
                  className={locale === locale2 ? "active" : undefined}
                >
                  <span>{locale2}</span>
                </Link>
              ))}
            </div>
            <div
              css={`
                font-size: 0;
              `}
            >
              <button
                css={`
                  padding: 0;
                  background: none;
                  outline: none;
                  border: none;
                  font-size: inherit;
                  padding: 4px;
                  margin-left: 16px;
                  cursor: pointer;
                `}
                onClick={e => {
                  e.preventDefault();
                  setOpen(!open);
                }}
                aria-label="menu"
              >
                {!open && (
                  <svg
                    width="26"
                    height="19"
                    viewBox="0 0 29 19"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <line
                      x1="0.242676"
                      y1="1.25"
                      x2="29.0003"
                      y2="1.25"
                      stroke="black"
                      strokeWidth="1.5"
                    />
                    <line
                      x1="0.242676"
                      y1="8.99976"
                      x2="29.0003"
                      y2="8.99976"
                      stroke="black"
                      strokeWidth="1.5"
                    />
                    <line
                      x1="0.242676"
                      y1="17.3953"
                      x2="29.0003"
                      y2="17.3953"
                      stroke="black"
                      strokeWidth="1.5"
                    />
                  </svg>
                )}
                {open && (
                  <svg
                    width="26"
                    height="26"
                    viewBox="0 0 26 26"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M25.022 0.969028L0.977539 24.799"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M0.977539 1.20105L25.022 25.031"
                      stroke="black"
                      strokeWidth="1.5"
                      strokeMiterlimit="10"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>
        {open && (
          <>
            <div
              css={`
                ${colors.sans}
                font-size: 15px;
                line-height: 30px;
                letter-spacing: 0.08em;
                text-transform: uppercase;
                a,
                span {
                  color: inherit;
                  text-decoration: none;
                  &.active {
                    font-weight: 700;
                  }
                }
                > a,
                span {
                  display: block;
                  text-align: center;
                  padding: 20px;
                  line-height: 1;
                  border-top: 1px solid #000;
                  padding-top: 22px;
                }
              `}
            >
              {(() => {
                return nodes.map(v => {
                  if (v.locale !== locale) return <></>;
                  if (v.treeParent !== null) return <></>;
                  if (!v.showInHeader) return <></>;
                  if (v.isEmpty)
                    return (
                      <>
                        <span
                          tabIndex={0}
                          css={`
                            cursor: pointer;
                            user-select: none;
                          `}
                          className={
                            (subLinks?.originalId === v.originalId &&
                              subLinks?.locale === v.locale) ||
                            (v.originalId === parent?.originalId &&
                              v.locale === parent?.locale)
                              ? "active"
                              : undefined
                          }
                          onClick={e => {
                            e.preventDefault();
                            if (
                              subLinks?.originalId === v.originalId &&
                              subLinks?.locale === v.locale
                            )
                              return setSubLinks(undefined);
                            setSubLinks({
                              originalId: v.originalId,
                              locale: v.locale,
                            });
                          }}
                        >
                          {v.title}
                        </span>
                        {subLinks?.originalId === v.originalId &&
                          subLinks?.locale === locale && (
                            <div
                              css={`
                                display: grid;
                                grid-gap: 10px;
                                text-align: center;
                                > a {
                                  text-transform: none;
                                }
                                margin-bottom: 15px;
                              `}
                            >
                              {nodes.map(v2 => {
                                if (
                                  !(
                                    v2.treeParent?.originalId ===
                                      subLinks.originalId &&
                                    v2.treeParent?.locale === subLinks.locale
                                  )
                                )
                                  return <></>;
                                return (
                                  <>
                                    <Link
                                      to={v2.path}
                                      className={
                                        originalId === v2.originalId &&
                                        locale === v2.locale
                                          ? "active"
                                          : undefined
                                      }
                                    >
                                      {v2.title}
                                    </Link>
                                  </>
                                );
                              })}
                            </div>
                          )}
                      </>
                    );
                  return (
                    <>
                      <Link
                        to={v.path}
                        className={
                          originalId === v.originalId && locale === v.locale
                            ? "active"
                            : undefined
                        }
                      >
                        {v.title}
                      </Link>
                    </>
                  );
                });
              })()}
            </div>
          </>
        )}
      </div>
    </>
  );
};

{/*  const JoinMailchimpForm: React.FC<{
  newsletterThankYou: string;
}> = ({ newsletterThankYou, children }) => {
  const [showThankYou, setShowThankYou] = React.useState<undefined | string>(
    undefined
  );
  const [loading, setLoading] = React.useState(false);
  if (typeof showThankYou !== "undefined")
    return (
      <>
        <div
          css={`
            ${colors.ag["Body P Large"]}
            > p {
              margin-bottom: 0.75em;
            }
            a {
              color: inherit;
            }
          `}
          dangerouslySetInnerHTML={{ __html: showThankYou }}
        />
      </>
    );
  return (
    <form
      onSubmit={e => {
        e.preventDefault();
        if (loading) return;
        const fd = new FormData(e.currentTarget);
        (async () => {
          const params: { [k: string]: string } = {
            EMAIL: fd.get("email")!.toString(),
          };
          const query = Object.keys(params)
            .map(
              k => encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
            )
            .join("&");
          const cbname = `cb${Math.random().toString(16).substring(2)}`;
          (window as any)[cbname] = (v: any) => {
            if (v.result === "error") {
              setShowThankYou(v.msg);
            } else setShowThankYou(newsletterThankYou);
            setLoading(false);
          };
          const el = document.createElement("script");
          el.src = `https://emkine.us18.list-manage.com/subscribe/post-json?u=4d52c0ebf4aa984b91bea4837&id=48a2b9ce54&${query}&c=${cbname}`;
          document.body.appendChild(el);
          setLoading(true);
        })();
      }}
    >
      {children}
    </form>
  );
};
*/}

const Layout: React.FC<Props> = ({
  pageContext,
  children,
  pathname,
  seoMetaTags,
}) => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsSite {
        faviconMetaTags {
          tags
        }
      }
      allDatoCmsFooter {
        nodes {
          locale
          slogan
          socialMediaLink {
            icon {
              url
              width
              height
              alt
            }
            externalLinkTo
          }
          postalAddress
          billing
          cookiesText
          privacyLink {
            ... on DatoCmsExternalLnk {
              externalLinkTo
              linkText
            }
            ... on DatoCmsInternalLink {
              linkText
              linkTo {
                originalId
                locale
              }
            }
          }
          feedbackLink {
            ... on DatoCmsExternalLnk {
              externalLinkTo
              linkText
            }
            ... on DatoCmsInternalLink {
              linkText
              linkTo {
                originalId
                locale
              }
            }
          }
        }
      }
    }
  `);
  const datoCmsFooter = data.allDatoCmsFooter.nodes.find(
    ({ locale }) => locale === pageContext.locale
  );
  const { locale } = pageContext;
  return (
    <>
      <Nav locale={pageContext.locale} originalId={pageContext.originalId} />
      <MobileNav
        locale={pageContext.locale}
        originalId={pageContext.originalId}
      />
      <div
        css={`
          max-width: 1920px;
          margin: 0 auto 0;
          background: #fff;
        `}
      >
        {children}
      </div>
      {/* {locale === "fi" && (
        <div
          css={`
            background: ${colors.accent};
            padding-top: 86px;
            padding-bottom: 75px;
            @media (max-width: 640px) {
              padding-top: 31px;
              padding-bottom: 55px;
            }
          `}
        >
            <Container>
            <div
              css={`
                display: grid;
                grid-template-columns: 1fr 2fr;
                grid-gap: 95px;
                @media (max-width: 640px) {
                  grid-template-columns: 1fr;
                  grid-gap: 29px;
                }
              `}
            >
              <div
                css={`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                `}
              >
                <object
                  aria-label="Mail"
                  type="image/svg+xml"
                  data={require("../images/mail.svg").default}
                  width="252"
                  height="204"
                  css={`
                    min-width: 252px;
                  `}
                />
              </div>
              <div>
                <h2
                  css={`
                    ${colors.ag.H2}
                    @media(max-width: 640px) {
                      text-align: center;
                    }
                  `}
                >
                  {datoCmsFooter.newsletterText}
                </h2>
                <div
                  css={`
                    height: 40px;
                    @media (max-width: 640px) {
                      height: 25px;
                    }
                  `}
                />
                <JoinMailchimpForm
                  newsletterThankYou={datoCmsFooter.newsletterThankYou}
                >
                  <div
                    css={`
                      display: flex;
                      gap: 22px;
                      flex-wrap: wrap;
                      flex-basis: min-content;
                      @media (max-width: 640px) {
                        justify-content: center;
                      }
                      > input {
                        max-width: 355px;
                      }
                    `}
                  >
                    <Input
                      name="email"
                      type="email"
                      required
                      label={(() => {
                        function assertNever(shouldBeNever: never): never {
                          throw new Error("Was not never: " + shouldBeNever);
                        }
                        switch (locale) {
                          case "fi":
                            return "Sähköposti";
                          default:
                            assertNever(locale);
                        }
                      })()}
                    />
                    <button
                      css={`
                        ${colors.button}
                        ${colors.ag["Body Text CTA"]}
                      `}
                    >
                      {(() => {
                        if (locale === "fi") return "Tilaa";
                        if (locale === "en") return "Order";
                      })()}
                    </button>
                  </div>
                </JoinMailchimpForm>
              </div>
            </div>
          </Container>
        </div>
      )}*/}
      <Container
        css={`
          @media (max-width: 1024px) {
            display: none;
          }
        `}
      >
        <div
          css={`
            height: 95px;
          `}
        />
        <div
          css={`
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            a {
              color: inherit;
              text-decoration: none;
            }
          `}
        >
          <div
            css={`
              grid-area: 1 / 1 / span 1 / span 2;
            `}
          >
            <object
              aria-label="Emkine"
              type="image/svg+xml"
              data={require("../images/emkine-no-whitespace.svg").default}
              width="332"
              height="62"
              css={`
                width: 100%;
                height: auto;
                max-width: 332px;
              `}
            />
            <div
              css={`
                height: 50px;
              `}
            />
            <div
              dangerouslySetInnerHTML={{ __html: datoCmsFooter.slogan }}
              css={`
                > h3 {
                  ${colors.text}
                  font-size: 21px;
                  line-height: 42px;
                  letter-spacing: -0.015em;
                }
                > p {
                  ${colors.sans}
                  font-size: 15px;
                  line-height: 19px;
                }
              `}
            />
          </div>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: datoCmsFooter.postalAddress }}
              css={`
                ${colors.sans}
                > h4 {
                  ${colors.text}
                  font-size: 18px;
                  line-height: 22px;
                  letter-spacing: -0.015em;
                  margin-bottom: 15px;
                }
                font-size: 15px;
                line-height: 19px;
                letter-spacing: -0.015em;
                p {
                  margin-bottom: 1em;
                }
              `}
            />
          </div>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: datoCmsFooter.billing }}
              css={`
                ${colors.sans}
                > h4 {
                  ${colors.text}
                  font-size: 18px;
                  line-height: 22px;
                  letter-spacing: -0.015em;
                  margin-bottom: 15px;
                }
                font-size: 15px;
                line-height: 19px;
                letter-spacing: -0.015em;
                p {
                  margin-bottom: 1em;
                }
              `}
            />
          </div>
          <div
            css={`
              display: flex;
              gap: 30px;
              flex-direction: column;
              ${colors.sans}
              font-size: 15px;
              line-height: 1;
              letter-spacing: 0.08em;
              text-transform: uppercase;
              p {
                margin: 0;
              }
              a {
                color: inherit;
                text-decoration: none;
              }
            `}
          >
            {pageContext.nodes.map(v => {
              if (v.locale !== pageContext.locale) return <></>;
              if (v.treeParent !== null) return <></>;
              if (!v.showInFooter) return <></>;
              return (
                <p>
                  <a href={v.path}>{v.title}</a>
                </p>
              );
            })}
          </div>
        </div>
        <div style={{ height: 30 }} />
      </Container>
      <Container
        css={`
          display: none;
          @media (max-width: 1024px) {
            display: block;
          }
        `}
      >
        <div
          css={`
            height: 61px;
          `}
        />
        <div
          css={`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            a {
              color: inherit;
              text-decoration: none;
            }
          `}
        >
          <div
            css={`
              grid-area: 1 / 1 / span 1 / span 2;
            `}
          >
            <object
              aria-label="Emkine"
              type="image/svg+xml"
              data={require("../images/emkine-no-whitespace.svg").default}
              width="332"
              height="62"
              css={`
                width: 100%;
                max-width: 332px;
                height: auto;
              `}
            />
            <div
              css={`
                height: 36px;
              `}
            />
            <div
              dangerouslySetInnerHTML={{ __html: datoCmsFooter.slogan }}
              css={`
                > h3 {
                  ${colors.text}
                  font-size: 21px;
                  line-height: 42px;
                  letter-spacing: -0.015em;
                }
                > p {
                  ${colors.sans}
                  font-size: 15px;
                  line-height: 19px;
                }
              `}
            />
            <div
              css={`
                height: 50px;
              `}
            />
            <div>
              <MyLink
                cta={datoCmsFooter.feedbackLink}
                css={`
                  ${colors.button}
                  ${colors.ag["Body Text CTA"]}
                  display: inline-block;
                `}
              />
            </div>
            <div
              css={`
                height: 50px;
              `}
            />
          </div>
          <div
            css={`
              grid-area: 2 / 1 / span 1 / span 1;
            `}
          >
            <div
              dangerouslySetInnerHTML={{ __html: datoCmsFooter.postalAddress }}
              css={`
                ${colors.sans}
                > h4 {
                  ${colors.text}
                  font-size: 18px;
                  line-height: 22px;
                  letter-spacing: -0.015em;
                  margin-bottom: 15px;
                }
                font-size: 15px;
                line-height: 19px;
                letter-spacing: -0.015em;
                p {
                  margin-bottom: 1em;
                }
              `}
            />
            <div
              css={`
                height: 50px;
              `}
            />
            <div
              dangerouslySetInnerHTML={{ __html: datoCmsFooter.billing }}
              css={`
                ${colors.sans}
                > h4 {
                  ${colors.text}
                  font-size: 18px;
                  line-height: 22px;
                  letter-spacing: -0.015em;
                  margin-bottom: 15px;
                }
                font-size: 15px;
                line-height: 19px;
                letter-spacing: -0.015em;
                p {
                  margin-bottom: 1em;
                }
              `}
            />
          </div>
          <div
            css={`
              display: flex;
              gap: 30px;
              flex-direction: column;
              ${colors.sans}
              font-size: 15px;
              line-height: 1;
              letter-spacing: 0.08em;
              text-transform: uppercase;
              p {
                margin: 0;
              }
              a {
                color: inherit;
                text-decoration: none;
              }
            `}
          >
            {pageContext.nodes.map(v => {
              if (v.locale !== pageContext.locale) return <></>;
              if (v.treeParent !== null) return <></>;
              if (!v.showInFooter) return <></>;
              return (
                <p>
                  <a href={v.path}>{v.title}</a>
                </p>
              );
            })}
          </div>
        </div>
        <div style={{ height: 30 }} />
      </Container>
      <Container>
        <hr
          css={`
            border: none;
            border-bottom: 1px solid rgba(0, 0, 0, 0.5);
          `}
        />
        <div style={{ height: 24 }} />
        <div
          css={`
            display: flex;
            gap: 34px;
            align-items: center;
            > div:nth-child(1) {
              flex: 1;
            }
            flex-basis: min-content;
            flex-wrap: wrap;
          `}
        >
          <div
            css={`
              display: flex;
              gap: 31px;
              align-items: center;
              font-size: 0;
              img {
                max-height: 32px;
              }
            `}
          >
            {datoCmsFooter.socialMediaLink.map(link => (
              <>
                <a href={link.externalLinkTo} target="_blank" rel="noreferrer">
                  <img
                    src={link.icon.url}
                    alt={link.icon.alt}
                    width={link.icon.width}
                    height={link.icon.height}
                  />
                </a>
              </>
            ))}
          </div>
          <MyLink
            cta={datoCmsFooter.privacyLink}
            css={`
              ${colors.sans}
              font-size: 15px;
              line-height: 30px;
              letter-spacing: 0.08em;
              text-transform: uppercase;
              color: inherit;
              text-decoration: none;
            `}
          />
          <div
            css={`
              @media (max-width: 1024px) {
                display: none;
              }
            `}
          >
            <MyLink
              cta={datoCmsFooter.feedbackLink}
              css={`
                ${colors.button}
                ${colors.ag["Body Text CTA"]}
                display: inline-block;
              `}
            />
          </div>
        </div>
        <div style={{ height: 36 }} />
      </Container>
      <Reset />
      <DeferredTypekit />
      <Consent
        html={datoCmsFooter.cookiesText}
        cta={datoCmsFooter.privacyLink}
        pathname={pathname}
      />
      <Helmet htmlAttributes={{ lang: locale }} />
      <Helmet>
        {data.datoCmsSite.faviconMetaTags.tags.map(
          ({ tagName, content, attributes }, k) =>
            React.createElement(tagName, { key: k, ...attributes }, content)
        )}
        {seoMetaTags.tags.map(({ tagName, content, attributes }, k) =>
          React.createElement(tagName, { key: k, ...attributes }, content)
        )}
      </Helmet>
      <Helmet>
        <meta name="theme-color" content="#FFCE2E" />
      </Helmet>
    </>
  );
};

const DeferredTypekit: React.FC = () => {
  const [show, setShow] = React.useState(false);
  React.useEffect(() => {
    setShow(true);
  }, []);
  if (!show) return <></>;
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="https://use.typekit.net/xkj6owx.css" />
      </Helmet>
    </>
  );
};

export const useLocalStorage = <T extends any>(
  key: string,
  initialValue: T
) => {
  const [storedValue, setStoredValue] = React.useState(() => {
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    } catch (error) {
      console.log(error);
      return initialValue;
    }
  });
  const setValue = React.useCallback(
    (value: T) => {
      try {
        setStoredValue(value);
        if (typeof value !== "undefined") {
          window.localStorage.setItem(key, JSON.stringify(value));
        } else {
          window.localStorage.removeItem(key);
        }
      } catch (error) {
        console.log(error);
      }
    },
    [key]
  );
  return [storedValue, setValue];
};

const TrackPageViews: React.FC<{ pathname: string }> = ({ pathname }) => {
  React.useLayoutEffect(() => {
    const tid = setTimeout(() => {
      try {
        const { gtag } = window as any;
        gtag("send", "pageview", pathname);
      } catch (err) {}
      try {
        const { ga } = window as any;
        ga("send", "pageview", pathname);
      } catch (err) {}
    }, 300);
    return () => {
      clearTimeout(tid);
    };
  }, [pathname]);
  return <></>;
};

type HTML = string;
const Consent: React.FC<{ html: HTML; cta: CTA; pathname: string }> = ({
  html,
  cta,
  pathname,
}) => {
  const { locale } = React.useContext(NodesContext);
  const [consent, setConsent] = useLocalStorage<{
    shown: boolean;
    given: boolean;
  }>("consent", { shown: false, given: false });
  return (
    <>
      {!consent.shown && (
        <>
          <div
            css={`
              position: fixed;
              bottom: 0;
              right: 0;
              z-index: 3;
            `}
          >
            <div
              css={`
                padding: 8px;
                width: 100%;
                max-width: 578px;
              `}
            >
              <div
                css={`
                  background: #fff;
                  border-radius: 8px;
                  padding: 16px;
                  box-shadow: 1px 1px 8px rgba(0, 0, 0, 0.125);
                `}
              >
                <div
                  css={`
                    ${colors.ag["Body P"]}
                    p {
                      margin-bottom: 0.75em;
                    }
                  `}
                  dangerouslySetInnerHTML={{ __html: html }}
                />
                <div
                  css={`
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    align-self: stretch;
                    gap: 8px;
                    > a {
                      padding: 8px;
                      text-decoration: none;
                    }
                    margin-top: 8px;
                  `}
                >
                  <button
                    css={`
                      ${colors.button}
                      ${colors.ag["Body Text CTA"]}
                      && {
                        padding: 8px 24px;
                      }
                    `}
                    onClick={e => {
                      e.preventDefault();
                      setConsent({ shown: true, given: true });
                    }}
                  >
                    {locale === "fi" && <>Hyväksyn</>}
                    {locale === "en" && <>Accept</>}
                  </button>
                  <MyLink
                    cta={cta}
                    css={`
                      color: #000;
                    `}
                  />
                </div>
              </div>
            </div>
          </div>
        </>
      )}
      {consent.given && (
        <>
          <Helmet>
            <script
              async
              src="https://www.googletagmanager.com/gtag/js?id=UA-51509605-1"
            ></script>
            <script>{`
window.dataLayer = window.dataLayer || [];
function gtag(){dataLayer.push(arguments);}
gtag('js', new Date());
gtag('config', 'UA-126526629-1');
`}</script>
            <script>{`
(function() {
	(function (i, s, o, g, r, a, m) {
		i['GoogleAnalyticsObject'] = r;
		i[r] = i[r] || function () {
				(i[r].q = i[r].q || []).push(arguments)
			}, i[r].l = 1 * new Date();
		a = s.createElement(o),
			m = s.getElementsByTagName(o)[0];
		a.async = 1;
		a.src = g;
		m.parentNode.insertBefore(a, m)
	})(window, document, 'script', 'https://google-analytics.com/analytics.js', 'ga');
	ga('create', 'UA-51509605-1', 'auto');
			ga('send', 'pageview');
	})();
`}</script>
            <script>{`
  (function(){
    window.ldfdr = window.ldfdr || {};
    (function(d, s, ss, fs){
      fs = d.getElementsByTagName(s)[0];
      function ce(src){
        var cs  = d.createElement(s);
        cs.src = src;
        setTimeout(function(){fs.parentNode.insertBefore(cs,fs)}, 1);
      }
      ce(ss);
    })(document, 'script', 'https://lftracker.leadfeeder.com/lftracker_v1_WqRvkEPpJWE8nY1O.js');
  })();
`}</script>
            <script type="text/javascript">
              {`
_linkedin_partner_id = "789763";
window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
window._linkedin_data_partner_ids.push(_linkedin_partner_id);
`}
            </script>
            <script type="text/javascript">{`
(function(){var s = document.getElementsByTagName("script")[0];
var b = document.createElement("script");
b.type = "text/javascript";b.async = true;
b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
s.parentNode.insertBefore(b, s);})();
`}</script>
            <noscript>
              {`
<img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=789763&fmt=gif" />
`}
            </noscript>
          </Helmet>
          <TrackPageViews pathname={pathname} />
        </>
      )}
      <Helmet>
        <meta
          name="google-site-verification"
          content="jb6nhvqoVaHjAJtCPqNQM_zjtuhCbPmvAAChqFbwd1A"
        />
      </Helmet>
    </>
  );
};

export default Layout;
