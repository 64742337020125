import React from "react";
import { colors, Container } from "../layout";
import type { DatoCmsSmall5050Hero } from "../page";

const Effects: React.FC<{
  contentRef: React.MutableRefObject<HTMLDivElement>;
}> = ({ contentRef }) => {
  const divRef = React.useRef<HTMLDivElement>(null!);
  const [size, setSize] = React.useState<
    undefined | { width: number; height: number }
  >(undefined);
  const [titleRect, setTitleRect] = React.useState<
    undefined | { x: number; y: number; w: number; h: number }
  >(undefined);
  React.useLayoutEffect(() => {
    var ro = new ResizeObserver(entries => {
      for (let entry of entries) {
        const cr = entry.contentRect;
        setSize({ width: cr.width, height: cr.height });
      }
    });
    ro.observe(divRef.current!);
    return () => {
      ro.disconnect();
    };
  }, []);
  React.useLayoutEffect(() => {
    const tick = () => {
      if (contentRef.current === null) return;
      const rect = contentRef.current.getBoundingClientRect();
      const rect0 = divRef.current!.getBoundingClientRect();
      setTitleRect({
        x: rect.left - rect0.left,
        y: rect.top - rect0.top,
        w: rect.width,
        h: rect.height,
      });
    };
    window.addEventListener("resize", tick, false);
    window.addEventListener("scroll", tick, false);
    const req = requestAnimationFrame(() => {
      tick();
    });
    return () => {
      window.removeEventListener("resize", tick, false);
      window.removeEventListener("scroll", tick, false);
      cancelAnimationFrame(req);
    };
  }, []);

  const mapped = (
    x: number,
    [a0, a1]: [number, number],
    [b0, b1]: [number, number]
  ) => {
    return ((x - a0) / (a1 - a0)) * (b1 - b0) + b0;
  };

  return (
    <>
      <div
        ref={divRef}
        css={`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: -1;
          pointer-events: none;
        `}
      >
        {typeof size !== "undefined" && typeof titleRect !== "undefined" && (
          <>
            <svg width={size.width} height={size.height}>
              <g
                stroke="#000"
                strokeWidth={1 / window.devicePixelRatio}
                fill="none"
              >
                <rect
                  x={mapped(
                    -40,
                    [0, 581],
                    [titleRect.x, titleRect.x + titleRect.w]
                  )}
                  y={
                    mapped(
                      349 - 90,
                      [0, 349],
                      [titleRect.y, titleRect.y + titleRect.h]
                    ) - size.height
                  }
                  width={size.width}
                  height={size.height}
                  rx={17.4442}
                />
                <rect
                  x={mapped(
                    141,
                    [0, 581],
                    [titleRect.x, titleRect.x + titleRect.w]
                  )}
                  y={
                    mapped(
                      349 - 161,
                      [0, 349],
                      [titleRect.y, titleRect.y + titleRect.h]
                    ) - size.height
                  }
                  width={size.width}
                  height={size.height}
                  rx={17.4442}
                />
              </g>
            </svg>
          </>
        )}
      </div>
    </>
  );
};

const Element: React.FC<Omit<DatoCmsSmall5050Hero, "__typename">> = props => {
  const contentRef = React.useRef<HTMLDivElement>(null!);
  return (
    <>
      <div
        css={`
          background: ${props.backgroundColor.hex};
          z-index: -2;
          position: relative;
        `}
      >
        <Effects contentRef={contentRef} />
        <Container>
          <div
            css={`
              display: grid;
              grid-template-columns: 1fr 1fr;
              @media (max-width: 640px) {
                > div:nth-child(1) {
                  grid-area: 2 / 1 / span 1 / span 2;
                }
                > div:nth-child(2) {
                  grid-area: 1 / 1 / span 1 / span 2;
                }
              }
            `}
          >
            <div
              css={`
                padding-top: 120px;
                padding-bottom: 120px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media (max-width: 640px) {
                  padding-top: 20px;
                  padding-bottom: 50px;
                }
                gap: 50px;
              `}
            >
              <h1
                css={`
                  ${colors.ag["H1 iso"]}
                `}
              >
                {props.title}
              </h1>
              {props.subheading !== "" && (
                <p
                  css={`
                    ${colors.ag["Body P Large"]}
                  `}
                >
                  {props.subheading}
                </p>
              )}
            </div>
            <div
              css={`
                padding: 30px 40px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                @media (max-width: 640px) {
                  padding-left: ${50 - 24}px;
                  padding-right: ${25 - 24}px;
                  padding-top: 25px;
                  padding-bottom: 25px;
                }
              `}
            >
              <div
                ref={contentRef}
                css={`
                  background: url(${props.image.url});
                  background-size: cover;
                  background-position: center center;
                  background-repeat: no-repeat;
                  aspect-ratio: ${props.image.width} / ${props.image.height};
                  border-radius: 18.5749px;
                `}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

export default Element;
