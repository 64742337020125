import React from "react";
import { colors, Container } from "../layout";
import { DatoCmsTeamHero, NodesContext } from "../page";
import { CSSTransition } from "react-transition-group";
import { css } from "styled-components/macro";

export const Slidedown: React.FC<{ in: boolean }> = ({
  in: inProp,
  children,
}) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [height, setHeight] = React.useState(0);
  React.useLayoutEffect(() => {
    if (divRef.current === null) return;
    const { current } = divRef;
    const cb = () => {
      current.style["display"] = "block";
      document.documentElement.scrollTop;
      const r = current.getBoundingClientRect();
      current.style["display"] = "none";
      setHeight(r.height);
    };
    window.addEventListener("resize", cb, false);
    window.addEventListener("scroll", cb, false);
    cb();
    return () => {
      window.removeEventListener("resize", cb, false);
      window.removeEventListener("scroll", cb, false);
    };
  }, []);
  return (
    <div
      css={`
        .example-enter {
          opacity: 0;
          height: 0;
          transform: translateY(-8px);
        }
        .example-enter-active {
          opacity: 1;
          transition: opacity 350ms ease-out, height 350ms ease-out,
            transform 350ms ease-out;
          height: ${height}px;
          overflow: hidden;
          transform: translateY(0);
        }
        .example-exit {
          opacity: 1;
          height: ${height}px;
          transform: translateY(0);
        }
        .example-exit-active {
          opacity: 0;
          transition: opacity 350ms ease-out, height 350ms ease-out,
            transform 350ms ease-out;
          height: 0;
          overflow: hidden;
          transform: translateY(-8px);
        }
      `}
    >
      <div
        ref={divRef}
        style={{
          display: "none",
        }}
      >
        {children}
      </div>
      <CSSTransition
        in={inProp}
        timeout={350}
        classNames="example"
        unmountOnExit
      >
        <div>{children}</div>
      </CSSTransition>
    </div>
  );
};

const Member: React.FC<DatoCmsTeamHero["teamMembers"][0]> = ({
  name,
  image,
  title,
  contactDetails,
  quote,
  description,
}) => {
  const [open, setOpen] = React.useState(false);
  const { locale } = React.useContext(NodesContext);
  return (
    <>
      <div
        css={`
          border: 1px solid #000000;
          box-sizing: border-box;
          border-radius: 20px;
          overflow: hidden;
          display: inline-block;
          width: 100%;
          margin-bottom: 40px;
          @media (max-width: 640px) {
            margin-bottom: 25px;
          }
          background: #fff;
        `}
      >
        {image !== null && (
          <div
            css={`
              background: url(${image.url});
              background-size: cover;
              background-position: center center;
              background-repeat: no-repeat;
              aspect-ratio: ${image.width} / ${image.height};
            `}
          />
        )}
        <div
          css={`
            padding: 33px 39px;
          `}
        >
          <h3
            css={`
              ${colors.ag.H3}
              margin-bottom: 0.75em;
            `}
          >
            {name}
          </h3>
          <div
            css={`
              ${colors.ag["Body P Large"]}
              p {
                margin-bottom: 0.75em;
              }
              a {
                color: inherit;
                text-decoration: none;
              }
              margin-bottom: -0.75em;
              display: grid;
              grid-gap: 0.75em;

              ${(description === null || description === "") &&
              css`
                margin-bottom: -1.25em;
              `}
            `}
          >
            <div
              css={`
                ${colors.ag["Body P Large"]}
              `}
              dangerouslySetInnerHTML={{ __html: title }}
            />
            <div
              css={`
                ${colors.ag["Body P Large"]}
              `}
              dangerouslySetInnerHTML={{ __html: contactDetails }}
            />
          </div>
        </div>
        {!(description === null || description === "") && (
          <>
            <Slidedown in={open}>
              <div
                css={`
                  padding: 0 37px;
                  padding-bottom: 30px;
                `}
              >
                <h4
                  css={`
                    ${colors.ag.H4}
                    margin-bottom: 1em;
                  `}
                >
                  {quote}
                </h4>
                <div
                  css={`
                    ${colors.ag["Body P"]}
                  `}
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
            </Slidedown>
            <button
              onClick={e => {
                e.preventDefault();
                setOpen(open => !open);
              }}
              css={`
                border: none;
                text-align: left;
                cursor: pointer;
                background: none;
                font-size: 0;
                padding: 18px 22px;
                padding-top: 17px;
                background: ${colors.primary};
                border-top: 1px solid #000;
                width: 100%;
                display: flex;
                justify-content: center;
                > span:nth-child(1) {
                  flex: 1;
                }
                color: #000;
              `}
            >
              <span
                css={`
                  ${colors.ag["Body Text CTA"]}
                `}
              >
                {(() => {
                  if (locale === "fi") return "Tutustu";
                  if (locale === "en") return "More";
                })()}
              </span>
              <span>
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g>
                    <g
                      transform={open ? "rotate(90, 9, 9)" : undefined}
                      css={`
                        transition: transform 150ms ease-out;
                      `}
                    >
                      <path
                        d="M8.78154 0.889982L8.78154 16.693"
                        stroke="black"
                        strokeWidth="2"
                        strokeMiterlimit="10"
                      />
                    </g>
                    <path
                      d="M0.796875 8.86865L16.9225 8.86865"
                      stroke="black"
                      strokeWidth="2"
                      strokeMiterlimit="10"
                    />
                  </g>
                </svg>
              </span>
            </button>
          </>
        )}
      </div>
    </>
  );
};

const Element: React.FC<Omit<DatoCmsTeamHero, "__typename">> = props => {
  return (
    <>
      <Container width={610}>
        <div
          css={`
            padding-top: 131px;
            text-align: center;
            @media (max-width: 640px) {
              padding-top: 75px;
            }
          `}
        >
          <h1
            css={`
              ${colors.disp};
              font-weight: 400;
              font-size: 65.46px;
              line-height: 1;
              margin-bottom: 41px;
            `}
          >
            {props.h1}
          </h1>
          <p
            css={`
              ${colors.text};
              font-weight: 400;
              font-size: 20.07px;
              line-height: 25.31px;
              letter-spacing: -0.015em;
            `}
          >
            {props.description}
          </p>
        </div>
      </Container>
      <div
        css={`
          height: 80px;
          @media (max-width: 640px) {
            height: 50px;
          }
        `}
      />
      <Container width={951}>
        <div
          css={`
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 41px;
            @media (max-width: 640px) {
              display: none;
            }
          `}
        >
          <div>
            {props.teamMembers
              .filter((_, k) => k % 2 == 0)
              .map(member => (
                <Member {...member} />
              ))}
          </div>
          <div>
            {props.teamMembers
              .filter((_, k) => k % 2 == 1)
              .map(member => (
                <Member {...member} />
              ))}
          </div>
        </div>
        <div
          css={`
            display: none;
            @media (max-width: 640px) {
              display: block;
            }
          `}
        >
          {props.teamMembers.map(member => (
            <Member {...member} />
          ))}
        </div>
      </Container>
      <div
        css={`
          height: 40px;
          @media (max-width: 640px) {
            height: 90px;
          }
        `}
      />
    </>
  );
};

export default Element;
