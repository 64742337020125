import React from "react";
import { colors, Container } from "../layout";
import type { DatoCmsPrivacyPolicy } from "../page";

const Element: React.FC<Omit<DatoCmsPrivacyPolicy, "__typename">> = props => {
  return (
    <>
      <Container width={615}>
        <div
          css={`
            padding-top: 121px;
            padding-bottom: 130px;
            ${colors.ag["Body P"]}
            p {
              margin-bottom: 1em;
            }
            h1 {
              ${colors.ag.H1}
              margin-bottom: 0.75em;
            }
            h2 {
              ${colors.ag.H2}
              margin-bottom: 0.75em;
            }
            h3 {
              ${colors.ag.H3}
              margin-bottom: 0.75em;
            }
            h4 {
              ${colors.ag.H4}
              margin-bottom: 0.75em;
            }
            h5 {
              ${colors.ag.H5}
              margin-bottom: 0.75em;
            }
            a {
              color: inherit;
            }
            ul {
              list-style: disc;
              padding-left: 20px;
              margin-bottom: 0.75em;
            }
            @media (max-width: 640px) {
              padding-top: 70px;
              padding-bottom: 70px;
            }
          `}
          dangerouslySetInnerHTML={{ __html: props.privacyPolicy }}
        />
      </Container>
    </>
  );
};

export default Element;
