import React from "react";
import { colors, Container } from "../layout";
import type { DatoCmsPersonHighlight } from "../page";
import { css } from "styled-components/macro";

const Element: React.FC<Omit<DatoCmsPersonHighlight, "__typename">> = props => (
  <>
    <div
      css={`
        background: ${colors.primary};
      `}
    >
      <Container>
        <div
          css={`
            padding-top: 101px;
            padding-bottom: 97px;
            display: flex;
            justify-content: center;
            align-items: center;
          `}
        >
          <div
            css={`
              background: #ffffff;
              border-radius: 17.4561px;
              overflow: hidden;
              width: 100%;
              max-width: 934.77px;
              display: grid;
              grid-template-columns: 1fr 1fr;
              @media (max-width: 1024px) {
                grid-template-columns: 1fr;
              }
            `}
          >
            <div
              css={`
                grid-area: 1 / 1 / span 1 / span 1;
                @media (max-width: 1024px) {
                  grid-area: 1 / 1 / span 1 / span 1;
                }
                ${props.person.image !== null &&
                css`
                  background: url(${props.person.image.url});
                  background-position: center center;
                  background-size: cover;
                  background-repeat: no-repeat;
                  aspect-ratio: ${props.person.image.width} /
                    ${props.person.image.height};
                `}
                ${props.person.image === null &&
                css`
                  background: #ededed;
                  aspect-ratio: 1 / 1;
                `}
              `}
            />
            <div
              css={`
                grid-area: 1 / 2 / span 1 / span 1;
                @media (max-width: 1024px) {
                  grid-area: 2 / 1 / span 1 / span 1;
                }
                display: flex;
                flex-direction: column;
                padding: 64px 32px;
                justify-content: center;
                align-items: center;
              `}
            >
              <h3
                css={`
                  ${colors.ag.H3}
                `}
              >
                {props.person.name}
              </h3>
              <div
                css={`
                  height: 32px;
                `}
              />
              <div
                css={`
                  > p {
                    ${colors.ag["Body P Large"]}
                    text-align: center;
                    line-height: 1.5em !important;
                    > a {
                      color: inherit;
                      text-decoration: none;
                    }
                  }
                `}
                dangerouslySetInnerHTML={{
                  __html: `${props.person.title}\n<br/>\n${props.person.contactDetails}`,
                }}
              />
            </div>
          </div>
        </div>
      </Container>
    </div>
  </>
);

export default Element;
