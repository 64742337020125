import React from "react";
import { colors, Container } from "../layout";
import { DatoCmsBlogHighlight, NodesContext } from "../page";
import { MyLink } from "./DatoCmsServicesContent";

export const BlogPosts: React.FC<{
  blogPosts: DatoCmsBlogHighlight["blogPosts"];
  news?: boolean;
}> = ({ blogPosts, news }) => {
  const { locale } = React.useContext(NodesContext);
  return (
    <>
      <div
        css={`
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          grid-gap: 43px;
          @media (max-width: 1024px) {
            grid-template-columns: 1fr;
          }
          @media (max-width: 640px) {
            grid-template-columns: 1fr;
            grid-gap: 25px;
          }
        `}
      >
        {blogPosts.map((v, k) => (
          <>
            <div
              css={`
                background: #fff;
                border: 1px solid #000;
                border-radius: 17.7618px;
                padding: 43px 51px;
                @media (max-width: 640px) {
                  padding: 23px 27px;
                  padding-bottom: 33px;
                }
                circle {
                  transition: cx 150ms ease-out, cy 150ms ease-out,
                    r 150ms ease-out;
                }
                &:hover {
                  svg.desktop {
                    circle {
                      cx: 49.9441px;
                      cy: 56.9441px;
                      r: 49.9441px;
                    }
                  }
                }
              `}
            >
              {!news && (
                <svg
                  width="58"
                  height="58"
                  viewBox="0 0 58 58"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  css={`
                    display: none;
                    @media (max-width: 640px) {
                      display: inline;
                    }
                    margin-bottom: 16px;
                  `}
                >
                  <circle
                    cx="23.75"
                    cy="34.2498"
                    r="23.75"
                    fill={["#C7BFB7", "#C87D88", "#FFCE2E"][k % 3]}
                  />
                  <path
                    d="M15.5861 34.17L13.0536 43.1873C12.7536 44.2537 13.74 45.24 14.8064 44.9401L23.8236 42.4076C24.9833 42.081 26.0363 41.4678 26.8827 40.6148L55.5141 11.9767C57.0537 10.4372 57.0537 7.94459 55.5141 6.40505L51.5953 2.48623C50.0558 0.946689 47.5632 0.946689 46.0236 2.48623L17.3789 31.111C16.5325 31.9574 15.9127 33.0104 15.5861 34.17Z"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                  />
                  <path
                    d="M42.7842 5.71858L50.4952 13.4296"
                    stroke="black"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                  />
                </svg>
              )}
              {news && (
                <svg
                  width="107"
                  height="107"
                  viewBox="0 0 107 107"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  css={`
                    display: none;
                    @media (max-width: 640px) {
                      display: inline;
                    }
                    margin-bottom: 16px;
                  `}
                >
                  <circle cx="63" cy="43" r="32.8594" fill="#C7BFB7"></circle>
                  <path
                    d="M98.5,76c1-1,1-2.7,0-3.7L85,58.7c-1.1-1.1-1.3-2.9-0.5-4.3c1.8-3.4,2.8-7.3,2.8-11.4
                  c0-13.5-10.9-24.4-24.3-24.4S38.7,29.5,38.7,43S49.6,67.4,63,67.4c5.2,0,10.1-1.7,14.1-4.5c1.5-1,3.5-1,4.8,0.2L94.8,76
                  C95.8,77,97.5,77,98.5,76L98.5,76z"
                    stroke="black"
                    stroke-width="1.77618"
                    stroke-miterlimit="10"
                  ></path>
                  <ellipse
                    cx="63"
                    cy="43"
                    rx="19"
                    ry="19.1"
                    stroke="black"
                    stroke-width="1.77618"
                    stroke-miterlimit="10"
                  ></ellipse>
                </svg>
              )}
              <h2
                css={`
                  ${colors.ag.H2}
                  word-break: break-word;
                `}
              >
                {!news && (
                  <svg
                    className="desktop"
                    width="107"
                    height="107"
                    viewBox="0 0 107 107"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    css={`
                      float: right;
                      margin-top: -20px;
                      margin-right: -20px;
                      margin-left: 10px;
                      margin-bottom: 10px;
                      @media (max-width: 640px) {
                        display: none;
                      }
                    `}
                  >
                    <circle
                      cx="63"
                      cy="43"
                      r="32.8594"
                      fill={["#C7BFB7", "#C87D88", "#FFCE2E"][k % 3]}
                    />
                    <path
                      d="M50.4098 45.0669L47.0351 57.0827C46.6354 58.5037 47.9498 59.8181 49.3708 59.4184L61.3866 56.0437C62.9319 55.6085 64.3351 54.7915 65.463 53.6547L103.615 15.4934C105.667 13.4419 105.667 10.1204 103.615 8.06895L98.3934 2.84697C96.3419 0.795477 93.0205 0.795477 90.969 2.84697L52.7988 40.9905C51.6709 42.1184 50.845 43.5216 50.4098 45.0669Z"
                      stroke="black"
                      strokeWidth="1.77618"
                      strokeMiterlimit="10"
                    />
                    <path
                      d="M86.6528 7.1543L96.9281 17.4295"
                      stroke="black"
                      strokeWidth="1.77618"
                      strokeMiterlimit="10"
                    />
                  </svg>
                )}
                {news && (
                  <svg
                    className="desktop"
                    width="107"
                    height="107"
                    viewBox="0 0 107 107"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    css={`
                      float: right;
                      margin-top: -20px;
                      margin-right: -20px;
                      margin-left: 10px;
                      margin-bottom: 10px;
                      @media (max-width: 640px) {
                        display: none;
                      }
                    `}
                  >
                    <circle cx="63" cy="43" r="32.8594" fill="#C7BFB7"></circle>
                    <path
                      d="M98.5,76c1-1,1-2.7,0-3.7L85,58.7c-1.1-1.1-1.3-2.9-0.5-4.3c1.8-3.4,2.8-7.3,2.8-11.4
                c0-13.5-10.9-24.4-24.3-24.4S38.7,29.5,38.7,43S49.6,67.4,63,67.4c5.2,0,10.1-1.7,14.1-4.5c1.5-1,3.5-1,4.8,0.2L94.8,76
                C95.8,77,97.5,77,98.5,76L98.5,76z"
                      stroke="black"
                      stroke-width="1.77618"
                      stroke-miterlimit="10"
                    ></path>
                    <ellipse
                      cx="63"
                      cy="43"
                      rx="19"
                      ry="19.1"
                      stroke="black"
                      stroke-width="1.77618"
                      stroke-miterlimit="10"
                    ></ellipse>
                  </svg>
                )}
                {v.title}
              </h2>
              <div style={{ height: 10 }} />
              <p
                css={`
                  ${colors.ag["Body P"]}
                `}
              >
                {v.publishedOn}
              </p>
              <div style={{ height: 20 }} />
              <p
                css={`
                  ${colors.ag["Body P"]}
                `}
              >
                {v.excerpt}
              </p>
              <div style={{ height: 30 }} />
              <MyLink
                cta={{
                  linkText: (() => {
                    function assertNever(shouldBeNever: never): never {
                      throw new Error("Was not never: " + shouldBeNever);
                    }
                    switch (locale) {
                      case "fi":
                        return "Lue lisää";
                      case "en":
                        return "Read more";
                      default:
                        assertNever(locale);
                    }
                  })(),
                  linkTo: { originalId: v.originalId, locale: v.locale },
                }}
                css={`
                  ${colors.button}
                  ${colors.ag["Body Text CTA"]}
                  display: inline-block;
                `}
              />
            </div>
          </>
        ))}
      </div>
    </>
  );
};

const Element: React.FC<Omit<DatoCmsBlogHighlight, "__typename">> = ({
  blogPosts,
}) => {
  const { locale } = React.useContext(NodesContext);
  return (
    <>
      <div
        css={`
          background: #ededed;
        `}
      >
        <Container>
          <div
            css={`
              padding-top: 78px;
              padding-bottom: 96px;
              @media (max-width: 640px) {
                padding-top: 48px;
                padding-bottom: 25px;
              }
            `}
          >
            <h2
              css={`
                ${colors.ag.H3}
              `}
            >
              {(() => {
                if (locale === "fi") return "Lue uusimmat kirjoituksemme";
                if (locale === "en") return "Read our latest blog posts";
              })()}
            </h2>
            <div
              css={`
                height: 40px;
                @media (max-width: 640px) {
                  height: 21px;
                }
              `}
            />
            <BlogPosts blogPosts={blogPosts} />
          </div>
        </Container>
      </div>
    </>
  );
};

export default Element;
