import React from "react";
import { colors, Container } from "../layout";
import type { DatoCmsHalfHalfLargeImage } from "../page";
import { MyLink } from "./DatoCmsServicesContent";

const Element: React.FC<
  Omit<DatoCmsHalfHalfLargeImage, "__typename">
> = props => (
  <>
    <div
      css={`
        background: ${colors.primary};
        padding-top: 46px;
        @media (max-width: 640px) {
          padding-top: 20px;
        }
      `}
    >
      <Container>
        <div
          css={`
            border: 1px solid #000000;
            border-radius: 17.4514px;
            border-bottom-left-radius: 0;
            border-bottom-right-radius: 0;
            border-bottom: none;
            padding: 74px 85px;
            padding-bottom: 66px;
            display: grid;
            grid-template-columns: 6fr auto 3fr;
            grid-gap: 32px;
            @media (max-width: 640px) {
              padding: 33px 39px;
              grid-template-columns: 1fr;
              padding-bottom: 52px;
            }
          `}
        >
          <h2
            css={`
              ${colors.ag.H2}
            `}
          >
            {props.title}
          </h2>
          <div
            css={`
              border-right: 1px solid #000;
              @media (max-width: 640px) {
                border-bottom: 1px solid #000;
              }
            `}
          ></div>
          <div>
            <div
              dangerouslySetInnerHTML={{ __html: props.bulletPoints }}
              css={`
                ${colors.ag["Body P Large"]}
                ul {
                  list-style: disc;
                  padding-left: 20px;
                }
              `}
            />
            <div style={{ height: 32 }} />
            <MyLink
              cta={props.cta}
              css={`
                ${colors.button}
                display: inline-block;
                ${colors.ag["Body Text CTA"]}
                text-decoration: none;
                color: #000;
                padding: 11px 43px;
                background: #fff;
                &:hover {
                  background: ${colors.primary};
                }
              `}
            />
          </div>
        </div>
      </Container>
    </div>
    <div
      css={`
        background: url(${props.image.url});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        padding-bottom: 46px;
        @media (max-width: 640px) {
          padding-bottom: 20px;
        }
        aspect-ratio: ${props.image.width} / ${props.image.height};
        aspect-ratio: 1454 / 691;
        display: flex;
        flex-direction: column;
        > div {
          flex: 1;
          display: flex;
          > div {
            flex: 1;
          }
        }
      `}
    >
      <Container>
        <div
          css={`
            border: 1px solid #000000;
            border-radius: 17.4514px;
            border-top-left-radius: 0;
            border-top-right-radius: 0;
            border-top: none;
            padding: 74px 85px;
            padding-bottom: 66px;
            display: grid;
            grid-template-columns: 6fr auto 3fr;
            grid-gap: 32px;
          `}
        />
      </Container>
    </div>
  </>
);

export default Element;
