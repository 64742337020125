import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import { Container } from "../layout";
import { DatoCmsReferenceIndex, NodesContext } from "../page";
import { PageNav } from "./DatoCmsBlogIndex";
import DatoCmsHalfHalfLargeImageRight from "./DatoCmsHalfHalfLargeImageRight";
import DatoCmsReferenceHighlight from "./DatoCmsReferenceHighlight";
import { useLocation } from "@reach/router";
import queryString from "query-string";

const Element: React.FC<Omit<DatoCmsReferenceIndex, "__typename">> = props => {
  const { locale } = React.useContext(NodesContext);

  const data = useStaticQuery<{
    allDatoCmsReference: {
      nodes: {
        slug: string;
        originalId: string;
        locale: string;
        client: string;
        quote: string;
        nameTitle: string;
      }[];
    };
  }>(graphql`
    query {
      allDatoCmsReference(sort: { order: ASC, fields: position }) {
        nodes {
          slug
          originalId
          locale
          client
          quote
          nameTitle
        }
      }
    }
  `);

  const nodes = data.allDatoCmsReference.nodes.filter(
    v => v.locale === locale && v.slug !== null
  );
  const { search } = useLocation();
  const page = React.useMemo(() => {
    const s = queryString.parse(search).page as string;
    if (typeof s === "undefined") return 0;
    return parseInt(s, 10);
  }, [search]);
  const nodes2 = nodes.slice(page * 6, page * 6 + 6);
  const groupTo = <T extends any>(xs: T[], n: number) => {
    const out: T[][] = [];
    for (let i = 0; i < xs.length; i += n) {
      out.push(xs.slice(i, i + n));
    }
    return out;
  };
  const nodes3 = groupTo(nodes2, 3);

  const startRef = React.useRef<HTMLDivElement>(null!);

  return (
    <>
      <div ref={startRef} />
      {nodes3.map((group, k) => (
        <>
          <DatoCmsReferenceHighlight
            id="posts"
            referencePosts={group}
            cta={null}
          />
          {k === 0 && page === 0 && (
            <>
              <DatoCmsHalfHalfLargeImageRight
                {...props.contentInTheMiddle[0]}
              />
            </>
          )}
        </>
      ))}
      <div
        css={`
          background: #ededed;
        `}
      >
        <Container>
          <PageNav pages={Math.ceil(nodes.length / 6)} page={page} />
        </Container>
      </div>
    </>
  );
};

export default Element;
