import React from "react";
import ContactForm from "../ContactForm";
import type { DatoCmsContactForm } from "../page";

const Element: React.FC<
  Omit<DatoCmsContactForm, "__typename" | "id">
> = props => {
  return (
    <>
      <ContactForm {...props} />
    </>
  );
};

export default Element;
