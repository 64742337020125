import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";
import { css } from "styled-components/macro";
import { colors, Container } from "../layout";
import { DatoCmsBlogIndex, NodesContext } from "../page";
import { BlogPosts } from "./DatoCmsBlogHighlight";
import { useLocation } from "@reach/router";
import queryString from "query-string";

export const PageNav: React.FC<{
  pages: number;
  page: number;
}> = ({ pages, page }) => {
  const { locale } = React.useContext(NodesContext);
  const { pathname } = useLocation();

  return (
    <>
      <div
        css={`
          min-height: 132px;
          display: flex;
          justify-content: center;
          align-items: center;
          ${colors.ag["Navigaatio"]}
          a {
            color: inherit;
            text-decoration: none;
          }
          > a:first-child {
            margin-right: 35px;
          }
          > a:last-child {
            margin-left: 35px;
          }
          ${pages < 2 &&
          css`
            > a {
              display: none;
            }
          `}
        `}
      >
        <Link
          to={page - 1 === 0 ? pathname : `${pathname}?page=${page - 1}`}
          css={`
            ${page === 0 &&
            css`
              visibility: hidden;
            `}
          `}
        >
          {locale === "fi" && <>Edellinen</>}
          {locale === "en" && <>Previous</>}
        </Link>
        {Array.from({ length: pages }).map((_, i) => (
          <Link
            to={i === 0 ? pathname : `${pathname}?page=${i}`}
            css={`
              display: inline-block;
              width: 35px;
              height: 35px;
              display: flex;
              justify-content: center;
              align-items: center;
              border: 1px solid transparent;
              border-radius: 50%;
              &.active {
                border: 1px solid #000;
              }
            `}
            className={i === page ? "active" : undefined}
          >
            <span>{i + 1}</span>
          </Link>
        ))}
        <Link
          to={`${pathname}?page=${page + 1}`}
          css={`
            ${page === pages - 1 &&
            css`
              visibility: hidden;
            `}
          `}
        >
          {locale === "fi" && <>Seuraava</>}
          {locale === "en" && <>Next</>}
        </Link>
      </div>
    </>
  );
};

const Element: React.FC<Omit<DatoCmsBlogIndex, "__typename">> = () => {
  const { locale } = React.useContext(NodesContext);

  const data = useStaticQuery<{
    allDatoCmsNews: {
      nodes: {
        originalId: string;
        locale: string;
        slug: string;
        title: string;
        publishedOn: string;
        excerpt: string;
      }[];
    };
  }>(graphql`
    query {
      allDatoCmsNews(sort: { order: DESC, fields: publishedOn }) {
        nodes {
          originalId
          locale
          slug
          title
          publishedOn(formatString: "D.M.YYYY")
          excerpt
        }
      }
    }
  `);

  const nodes = data.allDatoCmsNews.nodes.filter(
    v => v.locale === locale && v.slug !== null
  );
  const { search } = useLocation();
  const page = React.useMemo(() => {
    const s = queryString.parse(search).page as string;
    if (typeof s === "undefined") return 0;
    return parseInt(s, 10);
  }, [search]);
  const nodes2 = nodes.slice(page * 8, page * 8 + 8);

  const startRef = React.useRef<HTMLDivElement>(null!);

  return (
    <>
      <div ref={startRef} />
      <div
        css={`
          background: #ededed;
          padding-top: 130px;
          @media (max-width: 640px) {
            padding-top: 50px;
          }
        `}
      >
        <Container>
          <BlogPosts blogPosts={nodes2} news />
          <PageNav pages={Math.ceil(nodes.length / 8)} page={page} />
        </Container>
      </div>
    </>
  );
};

export default Element;
