import React from "react";
import "styled-components/macro";
import { css } from "styled-components/macro";
import { colors, Container, Input } from "./layout";
import { NodesContext } from "./page";
import { useLocation } from "@reach/router";

export const Textarea: React.FC<{ name: string; label: string }> = ({
  name,
  label,
}) => {
  return (
    <>
      <textarea
        name={name}
        rows={5}
        css={`
          background: #ffffff;
          border: none;
          border-radius: 10px;
          border: 1px solid #000;
          resize: none;
          ${colors.text}
          font-size: 18px;
          line-height: 22px;
          letter-spacing: -0.015em;
          padding: 8px 14px;
          outline: none;
          display: block;
          width: 335px;
          width: 100%;
          max-width: 100%;
          &::placeholder {
            color: rgba(0, 0, 0, 0.6);
          }
          &:focus {
            &::placeholder {
              color: rgba(0, 0, 0, 0);
            }
            border-color: ${colors.primary};
          }
        `}
        placeholder={label}
        spellCheck="false"
        autoCapitalize="no"
        autoCorrect="no"
        autoComplete="no"
      />
    </>
  );
};

function assertNever(shouldBeNever: never): never {
  throw new Error("Was not never: " + shouldBeNever);
}

const ContactForm: React.FC<{
  title: string;
  description: string;
  includeMessagePart: boolean;
  buttonText: string;
  addMorePadding: boolean;
  subscribeToMailchimpId: null | string;
  subscribedToMailchimpThankYouText: null | string;
  thankYouText: null | string;
}> = ({
  title,
  description,
  includeMessagePart,
  buttonText,
  addMorePadding,
  subscribeToMailchimpId,
  subscribedToMailchimpThankYouText,
  thankYouText,
}) => {
  const { locale } = React.useContext(NodesContext);
  const location = useLocation();
  const [showThankYou, setShowThankYou] = React.useState<undefined | string>(
    undefined
  );
  const [loading, setLoading] = React.useState(false);
  return (
    <>
      <div
        css={`
          background: #ededed;
          padding-top: 90px;
          padding-bottom: 90px;
          ${addMorePadding &&
          css`
            padding-bottom: 201px;
          `}
          @media (max-width: 640px) {
            padding: 60px 0;
          }
        `}
      >
        <Container width={615}>
          {title !== null && title !== "" && (
            <h1
              css={`
                ${colors.ag.H1}
                margin-bottom: 51px;
                text-align: center;
              `}
            >
              {title}
            </h1>
          )}
          <div
            css={`
              ${colors.ag["Body P Large"]}
              text-align: center;
              margin-bottom: 60px;
              > p {
                white-space: pre-wrap;
              }
            `}
          >
            <p>{description}</p>
          </div>
          <div
            css={`
              background: #ededed;
              border: 1px solid #000000;
              box-sizing: border-box;
              border-radius: 20px;
              padding: 60px;
              @media (max-width: 640px) {
                padding: 24px;
              }
            `}
          >
            {typeof showThankYou !== "undefined" && (
              <div
                css={`
                  ${colors.ag["Body P Large"]}
                  > p {
                    margin-bottom: 0.75em;
                  }
                  a {
                    color: inherit;
                  }
                  margin-bottom: 1em;
                `}
                dangerouslySetInnerHTML={{ __html: showThankYou }}
              />
            )}
            <form
              css={`
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 30px 18px;
                > div:nth-child(1) {
                  grid-area: 1 / 1 / span 1 / span 1;
                }
                > div:nth-child(2) {
                  grid-area: 1 / 2 / span 1 / span 1;
                }
                > div:nth-child(3) {
                  grid-area: 2 / 1 / span 1 / span 2;
                }
                > div:nth-child(4) {
                  grid-area: 3 / 1 / span 1 / span 2;
                }
                > div:nth-child(5) {
                  grid-area: 4 / 1 / span 1 / span 2;
                }
                > div:nth-child(6) {
                  grid-area: 5 / 1 / span 1 / span 2;
                }
                @media (max-width: 640px) {
                  > div:nth-child(1) {
                    grid-area: 1 / 1 / span 1 / span 2;
                  }
                  > div:nth-child(2) {
                    grid-area: 2 / 1 / span 1 / span 2;
                  }
                  > div:nth-child(3) {
                    grid-area: 3 / 1 / span 1 / span 2;
                  }
                  > div:nth-child(4) {
                    grid-area: 4 / 1 / span 1 / span 2;
                  }
                  > div:nth-child(5) {
                    grid-area: 5 / 1 / span 1 / span 2;
                  }
                  > div:nth-child(6) {
                    grid-area: 6 / 1 / span 1 / span 2;
                  }
                }
              `}
              onSubmit={e => {
                e.preventDefault();
                if (loading) return;
                const { currentTarget } = e;
                const fd = new FormData(currentTarget);

                const mailChimp = async () => {
                  const params: { [k: string]: string } = {
                    EMAIL: fd.get("email")!.toString(),
                    FNAME: fd.get("first_name")!.toString(),
                    LNAME: fd.get("last_name")!.toString(),
                    COMPANY: fd.get("company")!.toString(),
                  };
                  const query = Object.keys(params)
                    .map(
                      k =>
                        encodeURIComponent(k) +
                        "=" +
                        encodeURIComponent(params[k])
                    )
                    .join("&");
                  const cbname = `cb${Math.random().toString(16).substring(2)}`;
                  (window as any)[cbname] = (v: any) => {
                    if (v.result === "error") {
                      setShowThankYou(v.msg);
                    } else {
                      if (subscribedToMailchimpThankYouText !== null)
                        setShowThankYou(subscribedToMailchimpThankYouText);
                      currentTarget.reset();
                    }
                    setLoading(false);
                  };
                  const el = document.createElement("script");
                  if (subscribeToMailchimpId !== null) {
                    const m = subscribeToMailchimpId.match(
                      /^b_([0-9a-f]+)_([0-9a-f]+)$/
                    )!;
                    el.src = `https://emkine.us18.list-manage.com/subscribe/post-json?u=${encodeURIComponent(
                      m[1]
                    )}&id=${encodeURIComponent(m[2])}&${query}&c=${cbname}`;
                    document.body.appendChild(el);
                  }
                  setLoading(true);
                };
                const email = async () => {
                  const state = Object.fromEntries(fd.entries());
                  const data = { ...state };
                  fetch(
                    "https://79hmfbzbcl.execute-api.eu-central-1.amazonaws.com/default/send-email-emkine",
                    {
                      method: "POST",
                      mode: "cors",
                      cache: "no-cache",
                      headers: { "Content-Type": "application/json" },
                      body: JSON.stringify({
                        ...data,
                      }),
                    }
                  )
                    .then(v => v.json())
                    .then(() => {
                      if (thankYouText !== null) setShowThankYou(thankYouText);
                      setLoading(false);
                      currentTarget.reset();
                    });
                };
                if (
                  subscribeToMailchimpId !== null &&
                  subscribeToMailchimpId !== ""
                )
                  return mailChimp();
                email();
              }}
            >
              <div>
                <Input
                  name="first_name"
                  required
                  label={(() => {
                    switch (locale) {
                      case "fi":
                        return "Etunimi";
                      case "en":
                        return "First name";
                      default:
                        assertNever(locale);
                    }
                  })()}
                />
              </div>
              <div>
                <Input
                  name="last_name"
                  required
                  label={(() => {
                    switch (locale) {
                      case "fi":
                        return "Sukunimi";
                      case "en":
                        return "Last name";
                      default:
                        assertNever(locale);
                    }
                  })()}
                />
              </div>
              <div>
                <Input
                  name="email"
                  type="email"
                  required
                  label={(() => {
                    switch (locale) {
                      case "fi":
                        return "Sähköpostiosoite";
                      case "en":
                        return "Email";
                      default:
                        assertNever(locale);
                    }
                  })()}
                />
              </div>
              <div>
                <Input
                  name="company"
                  label={(() => {
                    switch (locale) {
                      case "fi":
                        return "Yritys";
                      case "en":
                        return "Company";
                      default:
                        assertNever(locale);
                    }
                  })()}
                />
              </div>
              {includeMessagePart && (
                <div>
                  <Textarea
                    name="message"
                    label={(() => {
                      switch (locale) {
                        case "fi":
                          return "Viesti";
                        case "en":
                          return "Message";
                        default:
                          assertNever(locale);
                      }
                    })()}
                  />
                </div>
              )}
              <div>
                <button
                  css={`
                    ${colors.button}
                    ${colors.ag["Body Text CTA"]}
                    margin: 0 auto 0;
                    margin-top: 10px;
                    @media (max-width: 640px) {
                      margin-left: 0;
                    }
                  `}
                >
                  {buttonText}
                </button>
              </div>
              <input type="hidden" name="pathname" value={location.pathname} />
              <input type="hidden" name="title" value={title} />
            </form>
          </div>
        </Container>
      </div>
    </>
  );
};
export default ContactForm;
