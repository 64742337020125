import { graphql } from "gatsby";
import * as React from "react";
import type {} from "styled-components/cssprop";
import DatoCmsHalfHalfLargeImage from "./blocks/DatoCmsHalfHalfLargeImage";
import DatoCmsHalfHalfSmallImage from "./blocks/DatoCmsHalfHalfSmallImage";
import DatoCmsHero from "./blocks/DatoCmsHero";
import DatoCmsServicesToDo from "./blocks/DatoCmsServicesToDo";
import DatoCmsReferenceHighlight from "./blocks/DatoCmsReferenceHighlight";
import DatoCmsBlogHighlight from "./blocks/DatoCmsBlogHighlight";
import DatoCmsServicesHero from "./blocks/DatoCmsServicesHero";
import DatoCmsServicesContent from "./blocks/DatoCmsServicesContent";
import DatoCmsPersonHighlight from "./blocks/DatoCmsPersonHighlight";
import DatoCmsHalfHalfLargeImageRight from "./blocks/DatoCmsHalfHalfLargeImageRight";
import DatoCmsBlogIndex from "./blocks/DatoCmsBlogIndex";
import DatoCmsNewsIndex from "./blocks/DatoCmsNewsIndex";
import DatoCmsReferenceIndex from "./blocks/DatoCmsReferenceIndex";
import DatoCmsSmall5050Hero from "./blocks/DatoCmsSmall5050Hero";
import DatoCmsSmall5050HeroV2 from "./blocks/DatoCmsSmall5050HeroV2";
import DatoCmsContactForm from "./blocks/DatoCmsContactForm";
import DatoCmsPrivacyPolicy from "./blocks/DatoCmsPrivacyPolicy";
import DatoCmsTeamHero from "./blocks/DatoCmsTeamHero";
import DatoCmsHalfHalfImageLeft from "./blocks/DatoCmsHalfHalfImageLeft";
import DatoCmsAbout from "./blocks/DatoCmsAbout";
import Layout from "./layout";

export type CTA =
  | { linkText: string; externalLinkTo: string }
  | { linkText: string; linkTo: { originalId: string; locale: string } };

export type Image = {
  url: string;
  width: number;
  height: number;
  alt: string;
};

export type DatoCmsHero = {
  __typename: "DatoCmsHero";
  id: string;
  titleH1: string;
  subtitle: string;
  jsonAnimatedImages: { json: string; description: string }[];
};
export type DatoCmsHalfHalfLargeImage = {
  __typename: "DatoCmsHalfHalfLargeImage";
  id: string;
  title: string;
  bulletPoints: string;
  image: Image;
  cta: CTA;
};
export type DatoCmsServicesToDo = {
  __typename: "DatoCmsServicesToDo";
  id: string;
  title: string;
  toDoList: {
    title: string;
    image: Image;
    bulletPoints: string;
  }[];
  description: string;
  cta: CTA;
};
export type DatoCmsBlogHighlight = {
  __typename: "DatoCmsBlogHighlight";
  id: string;
  blogPosts: {
    originalId: string;
    locale: string;
    title: string;
    excerpt: string;
  }[];
};
export type DatoCmsHalfHalfSmallImage = {
  __typename: "DatoCmsHalfHalfSmallImage";
  id: string;
  title: string;
  image: Image;
  cta: CTA;
};
export type DatoCmsReferenceHighlight = {
  __typename: "DatoCmsReferenceHighlight";
  id: string;
  referencePosts: {
    originalId: string;
    locale: string;
    client: string;
    quote: string;
    nameTitle: string;
  }[];
  cta: CTA | null;
};
export type DatoCmsServicesHero = {
  __typename: "DatoCmsServicesHero";
  id: string;
  title: string;
  subtitle: string;
  blurb: string;
  servicesDescriptions: {
    image: Image;
    title: string;
  }[];
};
export type DatoCmsServicesContent = {
  __typename: "DatoCmsServicesContent";
  id: string;
  serviceNumber: string;
  serviceTitle: string;
  serviceH1: string;
  serviceContent: string;
  serviceImage: Image;
  serviceBlurb: string;
  cta: CTA;
  backgroundColor: {
    hex: string;
  };
};
export type DatoCmsPersonHighlight = {
  __typename: "DatoCmsPersonHighlight";
  id: string;
  person: {
    name: string;
    image: Image | null;
    title: string;
    contactDetails: string;
  };
};
export type DatoCmsHalfHalfLargeImageRight = {
  __typename: "DatoCmsHalfHalfLargeImageRight";
  id: string;
  title: string;
  content: string;
  image: Image;
};
export type DatoCmsBlogIndex = {
  __typename: "DatoCmsBlogIndex";
  id: string;
};
export type DatoCmsNewsIndex = {
  __typename: "DatoCmsNewsIndex";
  id: string;
};
export type DatoCmsReferenceIndex = {
  __typename: "DatoCmsReferenceIndex";
  id: string;
  contentInTheMiddle: DatoCmsHalfHalfLargeImageRight[];
};
export type DatoCmsSmall5050Hero = {
  __typename: "DatoCmsSmall5050Hero";
  id: string;
  title: string;
  subheading: string;
  image: Image;
  backgroundColor: {
    hex: string;
  };
};
export type DatoCmsSmall5050HeroV2 = {
  __typename: "DatoCmsSmall5050HeroV2";
  id: string;
  content: string;
  image: Image;
  backgroundColor: {
    hex: string;
  };
  effect: number;
  showImageOnBottomOnMobile: boolean;
};
export type DatoCmsTeamHero = {
  __typename: "DatoCmsTeamHero";
  id: string;
  h1: string;
  description: string;
  teamMembers: {
    name: string;
    image: null | Image;
    title: string;
    contactDetails: string;
    quote: string;
    description: string;
  }[];
};
export type DatoCmsContactForm = {
  __typename: "DatoCmsContactForm";
  id: string;
  title: string;
  description: string;
  includeMessagePart: boolean;
  buttonText: string;
  addMorePadding: boolean;
  subscribeToMailchimpId: null | string;
  subscribedToMailchimpThankYouText: null | string;
  thankYouText: null | string;
};
export type DatoCmsPrivacyPolicy = {
  __typename: "DatoCmsPrivacyPolicy";
  id: string;
  privacyPolicy: string;
};
export type DatoCmsHalfHalfImageLeft = {
  __typename: "DatoCmsHalfHalfImageLeft";
  id: string;
  title: string;
  bulletPoints: string;
  image: Image;
  cta: CTA;
};
export type DatoCmsAbout = {
  __typename: "DatoCmsAbout";
  id: string;
  logowall: [
    {
      customerLogos: Image[];
    }
  ];
  cta: CTA;
};
type Block =
  | DatoCmsHero
  | DatoCmsHalfHalfLargeImage
  | DatoCmsServicesToDo
  | DatoCmsBlogHighlight
  | DatoCmsHalfHalfSmallImage
  | DatoCmsReferenceHighlight
  | DatoCmsServicesHero
  | DatoCmsServicesContent
  | DatoCmsPersonHighlight
  | DatoCmsHalfHalfLargeImageRight
  | DatoCmsBlogIndex
  | DatoCmsNewsIndex
  | DatoCmsReferenceIndex
  | DatoCmsSmall5050Hero
  | DatoCmsSmall5050HeroV2
  | DatoCmsTeamHero
  | DatoCmsContactForm
  | DatoCmsPrivacyPolicy
  | DatoCmsHalfHalfImageLeft
  | DatoCmsAbout;

export type PageContext = {
  locale: "fi" | "en";
  originalId: string;
  nodes: {
    originalId: string;
    locale: string;
    path: string;
    title: string;
    showInFooter: boolean;
    showInHeader: boolean;
    treeParent: null | { originalId: string; locale: string };
    isEmpty: boolean;
  }[];
};

type Props = {
  data: {
    datoCmsPage: {
      id: string;
      title: string;
      seoMetaTags: {
        tags: (
          | { tagName: string; content: string }
          | { tagName: string; attributes: { name: string; content: string } }
          | {
              tagName: string;
              attributes: { property: string; content: string };
            }
        )[];
      };
      content: Block[];
    };
  };
  pageContext: PageContext;
  location: { pathname: string };
};

export const NodesContext = React.createContext<{
  nodes: Props["pageContext"]["nodes"];
  locale: Props["pageContext"]["locale"];
}>(undefined!);

const Page: React.FC<Props> = ({
  data: { datoCmsPage },
  pageContext,
  location: { pathname },
}) => {
  return (
    <Layout
      pageContext={pageContext}
      pathname={pathname}
      seoMetaTags={datoCmsPage.seoMetaTags}
    >
      {datoCmsPage.content.map(props => {
        if (props.__typename === "DatoCmsHero") {
          const { __typename, ...rest } = props;
          return <DatoCmsHero {...rest} />;
        }
        if (props.__typename === "DatoCmsHalfHalfLargeImage") {
          const { __typename, ...rest } = props;
          return <DatoCmsHalfHalfLargeImage {...rest} />;
        }
        if (props.__typename === "DatoCmsServicesToDo") {
          const { __typename, ...rest } = props;
          return <DatoCmsServicesToDo {...rest} />;
        }
        if (props.__typename === "DatoCmsHalfHalfSmallImage") {
          const { __typename, ...rest } = props;
          return <DatoCmsHalfHalfSmallImage {...rest} />;
        }
        if (props.__typename === "DatoCmsReferenceHighlight") {
          const { __typename, ...rest } = props;
          return <DatoCmsReferenceHighlight {...rest} />;
        }
        if (props.__typename === "DatoCmsBlogHighlight") {
          const { __typename, ...rest } = props;
          return <DatoCmsBlogHighlight {...rest} />;
        }
        if (props.__typename === "DatoCmsServicesHero") {
          const { __typename, ...rest } = props;
          return <DatoCmsServicesHero {...rest} />;
        }
        if (props.__typename === "DatoCmsServicesContent") {
          const { __typename, ...rest } = props;
          return <DatoCmsServicesContent {...rest} />;
        }
        if (props.__typename === "DatoCmsPersonHighlight") {
          const { __typename, ...rest } = props;
          return <DatoCmsPersonHighlight {...rest} />;
        }
        if (props.__typename === "DatoCmsHalfHalfLargeImageRight") {
          const { __typename, ...rest } = props;
          return <DatoCmsHalfHalfLargeImageRight {...rest} />;
        }
        if (props.__typename === "DatoCmsBlogIndex") {
          const { __typename, ...rest } = props;
          return <DatoCmsBlogIndex {...rest} />;
        }
        if (props.__typename === "DatoCmsNewsIndex") {
          const { __typename, ...rest } = props;
          return <DatoCmsNewsIndex {...rest} />;
        }
        if (props.__typename === "DatoCmsReferenceIndex") {
          const { __typename, ...rest } = props;
          return <DatoCmsReferenceIndex {...rest} />;
        }
        if (props.__typename === "DatoCmsSmall5050Hero") {
          const { __typename, ...rest } = props;
          return <DatoCmsSmall5050Hero {...rest} />;
        }
        if (props.__typename === "DatoCmsSmall5050HeroV2") {
          const { __typename, ...rest } = props;
          return <DatoCmsSmall5050HeroV2 {...rest} />;
        }
        if (props.__typename === "DatoCmsTeamHero") {
          const { __typename, ...rest } = props;
          return <DatoCmsTeamHero {...rest} />;
        }
        if (props.__typename === "DatoCmsContactForm") {
          const { __typename, ...rest } = props;
          return <DatoCmsContactForm {...rest} />;
        }
        if (props.__typename === "DatoCmsPrivacyPolicy") {
          const { __typename, ...rest } = props;
          return <DatoCmsPrivacyPolicy {...rest} />;
        }
        if (props.__typename === "DatoCmsHalfHalfImageLeft") {
          const { __typename, ...rest } = props;
          return <DatoCmsHalfHalfImageLeft {...rest} />;
        }
        if (props.__typename === "DatoCmsAbout") {
          const { __typename, ...rest } = props;
          return <DatoCmsAbout {...rest} />;
        }
        return (
          <>
            {process.env.NODE_ENV !== "production" && (
              <pre>{JSON.stringify(props, null, 2)}</pre>
            )}
          </>
        );
      })}
    </Layout>
  );
};

const Page1: React.FC<Props> = props => {
  const { nodes, locale } = props.pageContext;
  return (
    <NodesContext.Provider value={{ nodes, locale }}>
      <Page {...props} />
    </NodesContext.Provider>
  );
};
export default Page1;

export const query = graphql`
  query ($originalId: String!, $locale: String!) {
    datoCmsPage(originalId: { eq: $originalId }, locale: { eq: $locale }) {
      id
      title
      seoMetaTags {
        tags
      }
      content {
        __typename
        ... on DatoCmsHero {
          id
          titleH1
          subtitle
          jsonAnimatedImages {
            json
            description
          }
        }
        ... on DatoCmsHalfHalfLargeImage {
          id
          title
          bulletPoints
          image {
            url
            width
            height
            alt
          }
          cta {
            ... on DatoCmsExternalLnk {
              externalLinkTo
              linkText
            }
            ... on DatoCmsInternalLink {
              linkText
              linkTo {
                originalId
                locale
              }
            }
          }
        }
        ... on DatoCmsServicesToDo {
          id
          title
          toDoList {
            title
            image {
              url
              width
              height
              alt
            }
            bulletPoints
          }
          description
          cta {
            ... on DatoCmsExternalLnk {
              externalLinkTo
              linkText
            }
            ... on DatoCmsInternalLink {
              linkText
              linkTo {
                originalId
                locale
              }
            }
          }
        }
        ... on DatoCmsBlogHighlight {
          id
          blogPosts {
            originalId
            locale
            title
            excerpt
          }
        }
        ... on DatoCmsHalfHalfSmallImage {
          id
          title
          image {
            url
            width
            height
            alt
          }
          cta {
            ... on DatoCmsExternalLnk {
              externalLinkTo
              linkText
            }
            ... on DatoCmsInternalLink {
              linkText
              linkTo {
                originalId
                locale
              }
            }
          }
        }
        ... on DatoCmsReferenceHighlight {
          id
          referencePosts {
            originalId
            locale
            client
            quote
            nameTitle
          }
          cta {
            ... on DatoCmsExternalLnk {
              externalLinkTo
              linkText
            }
            ... on DatoCmsInternalLink {
              linkText
              linkTo {
                originalId
                locale
              }
            }
          }
        }
        ... on DatoCmsServicesHero {
          id
          title
          subtitle
          blurb
          servicesDescriptions {
            image {
              url
              width
              height
              alt
            }
            title
          }
        }
        ... on DatoCmsServicesContent {
          id
          serviceNumber
          serviceTitle
          serviceH1
          serviceContent
          serviceImage {
            height
            width
            url
            alt
          }
          serviceBlurb
          cta {
            ... on DatoCmsExternalLnk {
              externalLinkTo
              linkText
            }
            ... on DatoCmsInternalLink {
              linkText
              linkTo {
                originalId
                locale
              }
            }
          }
          backgroundColor {
            hex
          }
        }
        ... on DatoCmsPersonHighlight {
          id
          person {
            name
            image {
              url
              width
              height
              alt
            }
            title
            contactDetails
          }
        }
        ... on DatoCmsHalfHalfLargeImageRight {
          id
          title
          content
          image {
            url
            width
            height
            alt
          }
        }
        ... on DatoCmsBlogIndex {
          id
        }
        ... on DatoCmsNewsIndex {
          id
        }
        ... on DatoCmsReferenceIndex {
          id
          contentInTheMiddle: content {
            ... on DatoCmsHalfHalfLargeImageRight {
              id
              title
              content
              image {
                url
                width
                height
                alt
              }
            }
          }
        }
        ... on DatoCmsSmall5050Hero {
          id
          title
          subheading
          image {
            url
            width
            height
            alt
          }
          backgroundColor {
            hex
          }
        }
        ... on DatoCmsSmall5050HeroV2 {
          id
          content
          image {
            url
            width
            height
            alt
          }
          backgroundColor {
            hex
          }
          effect
          showImageOnBottomOnMobile
        }
        ... on DatoCmsTeamHero {
          id
          h1
          description
          teamMembers {
            name
            image {
              url
              width
              height
              alt
            }
            title
            contactDetails
            quote
            description
          }
        }
        ... on DatoCmsContactForm {
          id
          title
          description
          includeMessagePart
          buttonText
          addMorePadding
          subscribeToMailchimpId
          subscribedToMailchimpThankYouText
          thankYouText
        }
        ... on DatoCmsPrivacyPolicy {
          id
          privacyPolicy
        }
        ... on DatoCmsHalfHalfImageLeft {
          id
          title
          bulletPoints
          image {
            url
            width
            height
            alt
          }
          cta {
            ... on DatoCmsExternalLnk {
              externalLinkTo
              linkText
            }
            ... on DatoCmsInternalLink {
              linkText
              linkTo {
                originalId
                locale
              }
            }
          }
        }
        ... on DatoCmsAbout {
          id
          logowall {
            customerLogos {
              url
              width
              height
              alt
            }
          }
          cta {
            ... on DatoCmsExternalLnk {
              externalLinkTo
              linkText
            }
            ... on DatoCmsInternalLink {
              linkText
              linkTo {
                originalId
                locale
              }
            }
          }
        }
      }
    }
  }
`;
