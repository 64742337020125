import React from "react";
import { colors, Container } from "../layout";
import type { DatoCmsHalfHalfLargeImageRight } from "../page";

const Element: React.FC<
  Omit<DatoCmsHalfHalfLargeImageRight, "__typename">
> = props => (
  <>
    <div
      css={`
        padding-top: 43px;
        padding-bottom: 43px;
        @media (max-width: 1024px) {
          padding-top: 20px;
          padding-bottom: 20px;
        }
        background: #ededed;
      `}
    >
      <Container>
        <div
          css={`
            border: 1px solid #000000;
            border-radius: 17.4455px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 92px;
            @media (max-width: 1024px) {
              grid-template-columns: 1fr;
              grid-gap: 80px;
            }
          `}
        >
          <div
            css={`
              padding: 77px 87px;
              @media (max-width: 1024px) {
                padding: 25px 38px;
              }
            `}
          >
            <h1
              css={`
                ${colors.ag.H1}
              `}
            >
              {props.title}
            </h1>
            <div
              css={`
                height: 50px;
              `}
            />
            <div
              css={`
                ${colors.ag["Body P"]}
                > p {
                  margin-bottom: 1em;
                }
                > p > strong {
                  font-weight: 600;
                }
                > ul {
                  ${colors.ag["Body P"]}
                  list-style: disc;
                  padding-left: 20px;
                  margin-bottom: 1em;
                }
              `}
              dangerouslySetInnerHTML={{ __html: props.content }}
            />
          </div>
          <div
            css={`
              display: flex;
              justify-content: center;
              align-items: center;
              @media (max-width: 1024px) {
                padding: 0 39px;
              }
            `}
          >
            <img src={props.image.url} alt={props.image.alt} />
          </div>
        </div>
      </Container>
    </div>
  </>
);

export default Element;
