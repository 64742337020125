import React from "react";
import { colors, Container } from "../layout";
import { CTA, NodesContext } from "../page";
import type { DatoCmsServicesContent } from "../page";
import { GatsbyLinkProps, Link } from "gatsby";
import { CanvasDemo } from "./DatoCmsServicesHero";
import { css } from "styled-components/macro";

interface CustomGatsbyLink
  extends Omit<GatsbyLinkProps<Record<string, unknown>>, "ref" | "to"> {
  cta: CTA;
}

export const MyLink: React.FC<CustomGatsbyLink> = props => {
  const { cta, ...rest } = props;
  const { linkText } = cta;
  const { nodes } = React.useContext(NodesContext);
  if (typeof (cta as any).linkTo !== "undefined") {
    const to = nodes.find(
      v =>
        v.originalId === (cta as any).linkTo.originalId &&
        v.locale === (cta as any).linkTo.locale
    )?.path;
    if (typeof to === "undefined")
      return (
        <a {...rest} href="">
          {linkText}
          {" <target missing>"}
        </a>
      );
    return (
      <Link {...rest} to={to}>
        {linkText}
      </Link>
    );
  }
  return (
    <a {...rest} href={(cta as any).externalLinkTo}>
      {linkText}
    </a>
  );
};

const Element: React.FC<Omit<DatoCmsServicesContent, "__typename">> = props => {
  const flipped = parseInt(props.serviceNumber) % 2 === 0;
  return (
    <>
      <div
        className="DatoCmsServicesContent"
        css={`
          background: ${props.backgroundColor.hex};
        `}
      >
        <div
          css={`
            display: grid;
            grid-template-columns: 4fr 3fr;
            ${flipped &&
            css`
              grid-template-columns: 3fr 4fr;
            `}
            grid-gap: 50px;
          `}
        >
          <div
            css={`
              grid-area: 1 / 2 / span 1 / span 1;
              ${flipped &&
              css`
                grid-area: 1 / 1 / span 1 / span 1;
              `}
              @media (max-width: 1024px) {
                display: none;
              }
              z-index: 1;
              display: flex;
              > div {
                flex: 1;
              }
            `}
          >
            <CanvasDemo
              blurb={props.serviceBlurb}
              image={props.serviceImage}
              highlightDirection={(() => {
                if (props.serviceImage !== null) return undefined;
                if (flipped) return "tl" as any;
                return "tr" as any;
              })()}
              balls={(() => {
                const left_or_right = flipped ? "l" : "r";
                if (props.serviceImage !== null)
                  return [
                    {
                      size: 40 * 2,
                      color: colors.primary,
                      direction: `t${left_or_right}`,
                    },
                    {
                      size: 43,
                      color: "#C87D88",
                      direction: `t${left_or_right}`,
                    },
                    {
                      size: 26,
                      color: "#000",
                      direction: `b${left_or_right}`,
                    },
                  ];
                return [
                  {
                    size: 43,
                    color: "#C87D88",
                    direction: `t${left_or_right}`,
                  },
                  {
                    size: 26,
                    color: "#000",
                    direction: `t${left_or_right}`,
                  },
                ];
              })()}
            />
          </div>
          <div
            css={`
              grid-area: 1 / 1 / span 1 / span 2;
            `}
          >
            <Container>
              <div
                css={`
                  padding-top: 120px;
                  padding-bottom: 72px;
                  margin-right: min(
                    ${(1 - 4 / 7) * 100}vw,
                    ${1440 * (1 - 4 / 7)}px
                  );
                  ${flipped &&
                  css`
                    margin-right: 0;
                    margin-left: min(
                      ${(1 - 4 / 7) * 100}vw,
                      ${1440 * (1 - 4 / 7)}px
                    );
                  `}
                  @media (max-width: 1024px) {
                    margin-right: 0;
                    margin-left: 0;
                    padding-top: 67px;
                  }
                  @media (max-width: 640px) {
                    margin-right: 0;
                    margin-left: 0;
                    padding-top: 67px;
                  }
                `}
              >
                <div
                  css={`
                    display: flex;
                    gap: 10px;
                  `}
                >
                  <div
                    css={`
                      ${colors.sans};
                      font-size: 15px;
                      line-height: 18px;
                      letter-spacing: -0.015em;
                    `}
                  >
                    {props.serviceNumber}
                  </div>
                  <div
                    css={`
                      ${colors.ag.H5}
                    `}
                  >
                    {props.serviceTitle}
                  </div>
                </div>
                <div
                  css={`
                    height: 23px;
                  `}
                />
                <h1
                  css={`
                    ${colors.ag["H1 iso"]}
                  `}
                >
                  {props.serviceH1}
                </h1>
                <div
                  css={`
                    height: 52px;
                    @media (max-width: 640px) {
                      height: 36px;
                    }
                  `}
                />
                {props.serviceContent
                  .split("<p><code>*Blurb or image*</code></p>")
                  .map((__html, k, self) => (
                    <>
                      <div
                        css={`
                          ${colors.ag["Body P"]}
                          > blockquote {
                            margin: 0;
                            ${colors.ag["Body P Large"]}
                            > p {
                              margin-bottom: 1em;
                            }
                          }
                          > p {
                            margin-bottom: 1em;
                          }
                          > h4 {
                            ${colors.ag.H4}
                            margin-bottom: 0.75em;
                          }
                          > ul {
                            list-style: disc;
                            padding-left: 20px;
                            margin-bottom: 1em;
                          }
                        `}
                        dangerouslySetInnerHTML={{ __html }}
                      />
                      {k === 0 && self.length > 1 && (
                        <>
                          <div
                            css={`
                              border-top: 1px solid #000;
                              border-bottom: 1px solid #000;
                              margin-bottom: 50px;
                              z-index: 1;
                              > div {
                                flex: 1;
                              }
                              display: none;
                              @media (max-width: 1024px) {
                                display: flex;
                              }
                              margin-left: -24px;
                              margin-right: -24px;
                            `}
                          >
                            <CanvasDemo
                              blurb={props.serviceBlurb}
                              image={props.serviceImage}
                              highlightDirection={(() => {
                                if (props.serviceImage !== null)
                                  return undefined;
                                if (flipped) return "tl" as any;
                                return "tr" as any;
                              })()}
                              balls={(() => {
                                const left_or_right = flipped ? "l" : "r";
                                if (props.serviceImage !== null)
                                  return [
                                    {
                                      size: 40 * 2,
                                      color: colors.primary,
                                      direction: `t${left_or_right}`,
                                    },
                                    {
                                      size: 43,
                                      color: "#C87D88",
                                      direction: `t${left_or_right}`,
                                    },
                                    {
                                      size: 26,
                                      color: "#000",
                                      direction: `b${left_or_right}`,
                                    },
                                  ];
                                return [
                                  {
                                    size: 43,
                                    color: "#C87D88",
                                    direction: `t${left_or_right}`,
                                  },
                                  {
                                    size: 26,
                                    color: "#000",
                                    direction: `t${left_or_right}`,
                                  },
                                ];
                              })()}
                            />
                          </div>
                        </>
                      )}
                    </>
                  ))}
                <div
                  css={`
                    height: 52px;
                    @media (max-width: 640px) {
                      height: 36px;
                    }
                  `}
                />
                <MyLink
                  cta={props.cta}
                  css={`
                    ${colors.button}
                    ${colors.ag["Body Text CTA"]}
                    display: inline-block;
                  `}
                />
              </div>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
};

export default Element;
